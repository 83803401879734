<script>
/* 针对一些特殊需求重写了el-select */
import { Select } from "element-ui";
export default {
  extends: Select,
  mounted() {
    this.handleElementClass();
  },
  methods: {
    handleElementClass() {
      if (this.value !== null && this.value.toString() !== "") {
        this.$el.classList.add("is-active");
      } else {
        this.$el.classList.remove("is-active");
      }
    },
    // 重写此方法
    deleteSelected(event) {
      event.stopPropagation();
      // const value = this.multiple ? [] : '';
      // 触发clear后把value改成null
      const value = this.multiple ? [] : null;
      this.$emit("input", value);
      this.emitChange(value);
      this.visible = false;
      this.$emit("clear");
    },
  },
  watch: {
    value: {
      handler() {
        this.handleElementClass();
      },
    },
  },
};
</script>

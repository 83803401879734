import store from "@/store";
import config from "@/config"


const LOGIN_ROUTE = "Login";
const HOME_ROUTE = config.homeName;
const whiteList = [HOME_ROUTE, LOGIN_ROUTE, "error403", "error404", "Test"]

let authModules = [];

export const auth = {
  inserted(el, binding) {
    const {
      value
    } = binding;
    if (!authModules.length) {
      store.state.auth.userAuthModules.map(item => {
        authModules.push(item.authMark)
      });
    }
    let every = binding.arg === "some" ? false : true;
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const filterAuthModules = value;
        const hasAuthModule = filterAuthModules[every ? "every" : "some"]((authModule) => {
          return authModules.includes(authModule);
        });
        if (!hasAuthModule) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error(`使用方式： v-auth="['add','eidt']"`);
    }
  }
};

export const $auth = {
  hasAuthModule(value, every = false) {
    let result = false;
    if (!authModules.length) {
      store.state.auth.userAuthModules.map(item => {
        authModules.push(item.authMark)
      });
    }
    if (value && value instanceof Array) {
      if (value.length > 0) {
        const filterAuthModules = value;
        const hasAuthModule = filterAuthModules[every ? "every" : "some"]((authModule) => {
          return authModules.includes(authModule);
        });
        result = hasAuthModule;
      }
    }
    return result;
  },
  hasRouterAuthModule(routerName) {
    if (whiteList.indexOf(routerName) !== -1) {
      return true;
    }
    let item = store.state.auth.userAuthModules.find(item => {
      return item.authRoute === routerName;
    })
    return item;
  }
};
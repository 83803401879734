/**
 * 通用正则表达式
 */

/**
 * 验证是否手机号
 * @param {String} value 验证值
 * @returns Boolean
 */
export const is_phone_number = (value) => {
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return reg.test(value);
}

/**
 * 验证是否联系电话
 * @param {String} value 验证值
 * @returns Boolean
 */
export const is_telephone = (value) => {
  const reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/
  return reg.test(value);
}

/**
 * 验证是否中文
 * @param {String} value 验证值
 * @param {Number} min 最小长度
 * @param {Number} max 最大长度
 * @returns Boolean
 */
export const is_chinese = (value, min = 0, max = 0) => {
  let reg = `^[\u4E00-\u9FA5]*$`;
  if (min && max) {
    reg = reg.replace("*", `{${min},${max}}`);
  }
  reg = new RegExp(reg);
  return reg.test(value);
}

/**
 * 验证是否英文
 * @param {String} value 验证值
 * @param {Number} type 【0-小写、1-大写】
 * @param {Number} min 最小长度
 * @param {Number} max 最大长度
 * @returns Boolean
 */
export const is_english = (value, type, min = 0, max = 0) => {
  let reg = `^[A-Za-z]*$`;
  if (type === 0) {
    reg = `^[a-z]*$`;
  } else if (type === 1) {
    reg = `^[A-Z]*$`;
  }
  if (min && max) {
    reg = reg.replace("*", `{${min},${max}}`);
  }
  reg = new RegExp(reg);
  return reg.test(value);
}

/**
 * 验证是否中文、英文
 * @param {String} value 验证值
 * @param {Number} min 最小长度
 * @param {Number} max 最大长度
 * @returns Boolean
 */
export const is_chinese_english = (value, min = 0, max = 0) => {
  let reg = `^[\u4E00-\u9FA5A-Za-z]*$`;
  if (min && max) {
    reg = reg.replace("*", `{${min},${max}}`);
  }
  reg = new RegExp(reg);
  return reg.test(value);
}

/**
 * 验证是否正整数
 * @param {String} value 验证值
 * @param {Number} min 最小长度
 * @param {Number} max 最大长度
 * @returns Boolean
 */
export const is_number = (value, min = 0, max = 0) => {
  let reg = `^[0-9]*$`;
  if (min && max) {
    reg = reg.replace("*", `{${min},${max}}`);
  }
  reg = new RegExp(reg);
  return Number(value) && reg.test(value);
}

/**
 * 验证是否浮点数
 * @param {String} value 验证值
 * @param {Number} min 小数点最小长度
 * @param {Number} max 小数点最大长度
 * @returns Boolean
 */
export const is_float = (value, min, max) => {
  let reg = /^[0-9]*(\d+)\.(\d)*$/;
  if (min && max) {
    if (reg.test(value)) {
      let length = String(value).split(".")[1].length;
      return length >= min && length <= max;
    } else {
      return false;
    }
  } else {
    return reg.test(value);
  }
}

/**
 * 验证是否数字/英文
 * @param {String} value 验证值
 * @param {Number} min 最小长度
 * @param {Number} max 最大长度
 * @returns Boolean
 */
export const is_number_english = (value, min, max) => {
  let reg = `^[0-9A-Za-z]*$`;
  if (min && max) {
    reg = reg.replace("*", `{${min},${max}}`);
  }
  reg = new RegExp(reg);
  return reg.test(value);
}

/**
 * 验证是否特殊字符
 * @param {String} value 验证值
 * @returns Boolean
 */
export const is_special = (value) => {
  const regEn = /[`!@#$%^&*()_+<>?:"{},.\/;'[\]]/im,
    regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im;
  return regEn.test(value) || regCn.test(value);
}


/**
 * 验证是否身份证
 * @param {String} value 验证值
 * @returns Boolean
 */
export const is_id_card = (value) => {
  const reg1 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/,
    reg2 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  return reg1.test(value) || reg2.test(value);
}

/**
 * 验证是否http地址
 * @param {String} value 
 * @returns Boolean
 */
export const is_http_url = (value) => {
  const reg = /(http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
  return reg.test(value);
}
import LayOut from '@/layout/index'
import order from './order'
import finance from './finance'
import channel from './channel'
import merchant from './merchant'
import warning from './warning'
import system from './system'

/**
 * 属性说明
 * title：标题
 * icon：菜单栏图标
 * affix：标签栏是否固定
 * isMenu：是否是菜单
 * cache：路由是否缓存
 */

export default [{
  path: '/login',
  name: 'Login',
  meta: {
    title: '登录'
  },
  component: () => import('@/views/login/index'),
},
{
  path: '/',
  component: LayOut,
  redirect: '/home',
  name: 'Home',
  meta: {
    isMenu: true,
  },
  children: [{
    path: 'home',
    name: 'Home',
    meta: {
      title: '工作台',
      icon: 'icon-yunyingzhongxin',
      isMenu: true,
      cache: true,
      affix: true
    },
    component: () => import('@/views/home/index')
  },]
},
...order,
...finance,
...channel,
...merchant,
...warning,
...system,
{
  name: "Calc",
  path: "/calc",
  meta: {
    title: '线下租金计算器',
    icon: 'icon-yunyingzhongxin',
    isMenu: true,
    cache: true,
  },
  component: () => import("@/views/calc/index"),
},
{
  name: "Test",
  path: "/test",
  meta: {
    title: '锁机表格统计',
    icon: 'icon-yunyingzhongxin',
    isMenu: false,
    cache: true,
  },
  component: () => import("@/views/test/index"),
},
{
  path: '/403',
  name: 'error403',
  meta: {
    isMenu: false
  },
  component: () => import('@/views/error-page/403')
},
{
  path: '*',
  name: 'error404',
  meta: {
    isMenu: false
  },
  component: () => import('@/views/error-page/404')
}
]
<template>
  <el-form
    ref="form"
    :model="form"
    :rules="rules"
    label-width="80px"
  >
    <el-form-item
      label="原密码"
      prop="oldPassword"
    >
      <el-input
        show-password
        v-model="form.oldPassword"
        placeholder="请输入原密码"
        maxlength="20"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="新密码"
      prop="newPassword"
    >
      <el-input
        show-password
        v-model="form.newPassword"
        placeholder="请输入新密码"
        maxlength="20"
      ></el-input>
    </el-form-item>
    <el-form-item
      label="确认密码"
      prop="confirmPassword"
    >
      <el-input
        show-password
        v-model="form.confirmPassword"
        placeholder="请输入确认密码"
        maxlength="20"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: "请输入原密码",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      let valid1 = false;
      this.$refs["form"].validate((valid) => {
        valid1 = valid;
      });
      this.$parent.$parent.form = this.form;
      return valid1;
    },
  },
};
</script>

<style>
</style>
import Vue from "vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Router from "vue-router";
import config from "@/config";
import store from "@/store";
import routes from "./routes"
import {
    getToken,
    getUserInfo
} from "@/utils/cookie";

import {
    $auth
} from "@/utils/auth/auth";

Vue.use(Router);
NProgress.configure({
    showSpinner: false,
});


// 防止路由跳转报错
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
//push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch((err) => err);
};
//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch((err) => err);
};


const router = new Router({
    routes,
});

const LOGIN_ROUTE = "Login";
const HOME_ROUTE = config.homeName;

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.meta.title) {
        // document.title = config.title + "-" + to.meta.title;
        document.title = config.title;
    }
    const authorization = getToken() && getUserInfo();
    let name, queryAuth = false;
    if (LOGIN_ROUTE === to.name) {
        if (authorization) {
            queryAuth = true;
            name = HOME_ROUTE;
        }
    } else {
        if (authorization) {
            queryAuth = true;
        } else {
            name = LOGIN_ROUTE;
        }
    }
    if (queryAuth) {
        let auth = store.state.auth;
        if (auth.userAuthModules.length) {
            routerTo(next, name, to.name);
        } else {
            store.dispatch("getUserAuth").then((res) => {
                routerTo(next, name, to.name);
            });
        }
    } else {
        routerTo(next, name, to.name);
    }
    NProgress.done();
});

router.afterEach(() => {
    NProgress.done();
});

const routerTo = (next, name, toName) => {
    // name
    //     ?
    //     next({
    //         name,
    //     }) :
    //     next();
    if (!$auth.hasRouterAuthModule(toName)) {
        next({
            name: "error403",
        });
    } else {
        name
            ?
            next({
                name,
            }) :
            next();
    }
};


export default router;

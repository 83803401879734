<script>
/* 针对一些特殊需求重写了el-date-picker */
import { DatePicker } from "element-ui";
export default {
  extends: DatePicker,
  mounted() {
    this.handleElementClass();
  },
  methods: {
    handleElementClass() {
      if (this.value !== null && this.value.toString() !== "") {
        this.$el.classList.add("is-value");
      } else {
        this.$el.classList.remove("is-value");
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.handleElementClass();
      },
    },
    // 重写此方法
    pickerVisible(val) {
      if (this.readonly || this.pickerDisabled) return;
      if (val) {
        this.showPicker();
        this.valueOnOpen = Array.isArray(this.value)
          ? [...this.value]
          : this.value;
      } else {
        this.hidePicker();
        this.emitChange(this.value);
        this.userInput = null;
        if (this.validateEvent) {
          this.dispatch("ElFormItem", "el.form.blur");
        }
        this.$emit("blur", this);
        this.blur();
      }
      // 加入自定义方法
      setTimeout(() => {
        this.handleElementClass();
      });
    },
  },
};
</script>

const PROTOCOL = window.location.protocol;
const HOSTNAME = window.location.hostname;
const VUE_APP_API = process.env.VUE_APP_API || PROTOCOL + '//' + HOSTNAME + '/api/';
const TOKEN_KEY = "TOKEN";
const USERINFO_KEY = "USERINFO";

let uploadPathUrl = '';
let uploadFilePathUrl = '';
let baseURL = VUE_APP_API;
uploadPathUrl = baseURL + 'common/upload';
uploadFilePathUrl = baseURL + '/settlementStatement/uploadCompressed';

export default {
    /**
     * @description 配置显示在浏览器标签的title
     */
    title: "拾贝壳租机平台",
    /**
     * @description 平台标识【1-银销易、2-优品易、3-供应商】
     */
    platform: 2,
    /**
     * @description cookie有效期，默认为7（天）
     */
    cookieExpires: "7d",
    /**
     * @description 默认打开工作台的路由name值，默认为Home
     */
    homeName: "Home",
    /**
     * @description 上传图片限制大小，默认为20（M）
     */
    imgLimitSize: 20,
    /**
     * @description 上传图片限制后缀
     */
    imgAccept: ".jpeg,.jpg,.png,.bmp,.dib,.gif,.jfif,.jpe,.tif,.tiff,.ico",
    /**
     * @description 上传图片Api
     */
    uploadPathUrl: uploadPathUrl,
    /**
     * @description Api地址【如果设置跨域则baseUrl不能设置绝对请求地址】
     */
    uploadFilePathUrl: uploadFilePathUrl,
    /**
     * @description Api地址【如果设置跨域则baseUrl不能设置绝对请求地址】
     */
    baseURL: baseURL,
    /**
     * @description 请求超时时间，默认为60（s）
     */
    timeout: 60,
    /**
     * @description token key
     */
    tokenKey: TOKEN_KEY,
    /**
     * @description 用户信息 key
     */
    userInfoKey: USERINFO_KEY,
    /**
     * @description 备案号
     */
    caseNumber: "粤ICP备2021062793号",
    /**
     * @description 默认日期时间格式
     */
    dateFormat: "yyyy-MM-dd",
    /**
     * @description 默认选择时间
     */
    defaultTime: ['00:00:00', '23:59:59'],
    /**
     * @description 长日期时间格式
     */
    longDateFormat: "yyyy-MM-dd HH:mm:ss",
    /**
     * @description cookie挂载顶级域名key数组
     */
    cookieMountTopLevelDominNameKeys: [TOKEN_KEY, USERINFO_KEY, "sevenFreeLogin"]
}
const state = {
    isCollapse: false,
    uniqueOpen: true,
}
const actions = {
    toggleSideBar({ commit }) {
        commit('TOOGGLE_SIDEBAR');
    }
}
const mutations = {
    TOOGGLE_SIDEBAR: state => {
        state.isCollapse = !state.isCollapse;
    }
}

export default {
    state,
    actions,
    mutations,
    namespaced: true
}

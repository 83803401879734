const state = {
    tagsView: []
}

const actions = {
    addTagsView({ commit }, tag) {
        commit('ADD_TAGS_VIEW', tag);
    },
    deleteTags({ commit, state }, tag) {
        return new Promise(resolve => {
            commit('DEL_TAGS', tag);
            resolve([...state.tagsView])
        })
    },
    delOthersViews({ commit }, view) {
        commit('DEL_OTHERS_VISITED_VIEWS', view)
    },
    closeAllTags({ commit }) {
        return new Promise(resolve => {
            commit('DEL_All_VIEWS');
            resolve()
        })
    },
}

const mutations = {
    ADD_TAGS_VIEW(state, tag) {
        let item = {};
        
        let flag = state.tagsView.some(v => {
            v.path === tag.path || v.name === tag.name ? item = v:'';
            return v.path === tag.path || v.name === tag.name;
        });
        if(flag) {
            // 重置页面传参
            if(Object.keys(tag.params).length) {
                item.params = tag.params;
                if(tag.path.indexOf('/:')) {
                    item.path = tag.path;
                }
            }
            if(Object.keys(tag.query).length) {
                item.query = tag.query;
            }
            return;
        }
        state.tagsView.push(
            Object.assign({}, tag, {
                title: tag.meta.title || 'no-name'
            })
        );
    },
    DEL_TAGS(state, tag) {
        for (const [index, item] of state.tagsView.entries()) {
            if(tag.path === item.path) {
                state.tagsView.splice(index, 1);
                break;
            }
        }
    },
    DEL_OTHERS_VISITED_VIEWS: (state, view) => {
      state.tagsView = state.tagsView.filter(v => {
        return v.path === view.path;
      })
    },
    DEL_All_VIEWS(state) {
        state.tagsView = state.tagsView.filter(v => v.meta.affix);
    }
}

export default {
    state,
    actions,
    mutations,
    namespaced: true
}
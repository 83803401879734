
import LayOut from '@/layout/index'

export default [{
  path: "/warning/",
  name: "Warning",
  component: LayOut,
  meta: {
    icon: "icon-dingdanguanli",
    isMenu: true,
    title: '风控管理'
  },
  children: [
    {
      name: "WarningQuery",
      path: "query",
      component: () => import("@/views/warning/query"),
      meta: {
        cache: false,
        isMenu: true,
        title: '查询入口'
      }
    },
    {
      name: "WarningHistory",
      path: "history",
      component: () => import("@/views/warning/history"),
      meta: {
        cache: false,
        isMenu: true,
        title: '查询历史'
      }
    },
    {
      name: "WarningDetails",
      path: "history/details/:reportNumber",
      component: () => import("@/views/warning/details/index"),
      meta: {
        title: '风控查询',
        parent: [{
          title: "详情",
          path: "/Warning/history",
        }],
      }
    }
  ]
}]
<script>
/* 针对一些特殊需求重写了el-input */
import { Input } from "element-ui";
export default {
  extends: Input,
  mounted() {
    this.handleElementClass();
  },
  methods: {
    handleElementClass() {
      if (this.value !== "" && this.value !== null) {
        this.$el.classList.add("is-active");
      } else {
        this.$el.classList.remove("is-active");
      }
    },
  },
  watch: {
    value: {
      handler() {
        if (this.value) {
          this.$emit("input", this.value.trim());
        }
        this.handleElementClass();
      },
    },
  },
};
</script>

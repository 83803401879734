<template>
  <div id="layout">
    <!-- 左侧菜单栏 -->
    <SideBar />
    <div class="lay-out-container scroll-bar" :class="{ active: isCollapse }">
      <div class="fixed-header">
        <LayOutHead />
        <!-- 面包屑 -->
        <!-- <Breadcrumb /> -->
      </div>
      <!-- 子路由视口 -->
      <LayOutApp />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb/Breadcrumb";
import { SideBar, TagsViews, LayOutApp, LayOutHead } from "./components";
export default {
  name: "LayOut",
  components: {
    SideBar,
    TagsViews,
    LayOutApp,
    LayOutHead,
    Breadcrumb,
  },
  computed: {
    ...mapGetters(["isCollapse"]),
  },
};
</script>

<style lang="scss" scoped>
#layout {
  display: flex;
  height: 100%;

  .lay-out-container {
    flex: 1;
    width: 100%;
    overflow: auto;
    margin-left: 200px;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    background: #f6f7f9;
    &.active {
      margin-left: 64px;
      .fixed-header {
        width: calc(100% - 64px);
      }
    }
    .fixed-header {
      padding: 0 30px;
      position: fixed;
      width: calc(100% - 200px);
      z-index: 4;
      background: #f6f7f9;
      padding-bottom: 15px;
    }
  }
}
</style>
<template>
  <div id="pagination" class="lay-out-pagination" v-show="total">
    <el-pagination
      background
      @current-change="currentChange"
      @size-change="sizeChange"
      :current-page="page"
      :page-sizes="pageSizes"
      :page-size="limit"
      :total="total"
      :layout="layout"
      :pager-count="pagerCount"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSizes: {
      type: Array,
      default: [20, 50, 100],
    },
    limit: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    pagerCount: {
      type: Number,
      default: 7,
    },
  },
  methods: {
    currentChange(page) {
      this.$emit("currentChange", page);
    },
    sizeChange(limit) {
      this.$emit("sizeChange", limit);
    },
  },
};
</script>

<style lang="scss" scope>
.lay-out-pagination {
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
}
</style>
// 表格通用混入
import moment from "moment";
import {
  mapActions
} from "vuex";
import {
  debounceFn,
  downloadMessage
} from "@/utils";
export default {
  data() {
    return {
      // 表格数据
      data: [],
      // tab栏数量数据
      stateCount: {},
      // 表格数据为空展示的文案
      emptyText: "暂无数据",
      // 选中行数据
      currentRow: {},
      // 页码
      page: 1,
      // 页码选择数组
      pageSizes: [10, 20, 50, 100, 200],
      // 每页数据条数
      limit: 10,
      // 总数据条数
      total: 0,
      // 查询数据的参数（隐示）
      params: {},
      // 待查询的对象（显示）
      query: {},
      // 上一次查询数据的参数
      prevQuery: {},
      // 增删改查方法对象
      curdMethod: {
        // query: "",
        // add: "",
        // edit: "",
        // delete: "",
        // batchHandle: "",
        // batchEditStatus: "",
        // export: "",
        // count: "",
      },
      // 增删改查方法回调
      curdCallback: {
        query: "",
        add: "",
        edit: "",
        delete: "",
        batchHandle: "",
        batchEditStatus: "",
        export: "",
        count: "",
        error: "" // code !== 1 的错误回调
      },
      // 增删改查属性
      curd: {
        id: "id", // id字段名称
        idList: "idList", // 批量操作id字段名称
        status: "status", // 状态字段名称
        isPage: true, // 是否有分页
        isCache: false, // 表格列属性是否缓存到session
        isSetHeight: false, // 是否设置表格自适应高度/最大高度
        isRowScroll: false // 表格横向滚动
      },
      // 表格 loading 属性
      loading: false,
      // 查询按钮 loading 属性
      searchLoading: false,
      // 删除按钮 loading 属性
      deleteLoading: false,
      // 批量处理按钮 loading 属性
      batchLoading: false,
      // 批量修改状态按钮 loading 属性
      batchEditStatusLoading: false,
      // 导出报表按钮 loading 属性
      exportLoading: false,
      // 导入报表按钮 loading 属性
      importLoading: false,
      // 保存按钮 loading 属性
      submitLoading: false,
      // Form 表单（显示）
      form: {},
      // 是否为新增类型的表单
      isAdd: true,
      // 弹窗操作
      operate: "",
      // 弹窗对象
      dialog: {
        // 弹窗是否开启
        visible: false,
        // 弹窗标题
        title: "",
      },
      // 时间参数
      date: [],
      dateValue1: [],
      dateValue2: [],
      dateValue3: [],
      dateValue4: [],
      // 表格多选数据
      multipleSelection: [],
      // 默认参数
      default: {
        query: {}, // 查询参数
        form: {}, // 表单参数
        date: [], // 时间参数
        dateValue1: [], // 时间参数1
        dateValue2: [], // 时间参数2
        dateValue3: [], // 时间参数3
        dateValue4: [], // 时间参数4
        columns: [], // 表格列数组
      },
      // tab切换防抖
      tabDebounce: false,
    };
  },
  created() {
    // 当从其他页面跳转进来并且有查询条件
    if (this.$route.params.isQuery) {
      this.initDefaultValue();
    }
    this.iniGeneralData();
    this.initQueryParams();
  },
  activated() {
    this.initQueryParams();
    this.handleQueryParams();
  },
  mounted() {
    // 当从其他页面跳转进来无查询条件、但是会在created中有默认一个月的时间参数
    if (!this.$route.params.isQuery) {
      this.initDefaultValue();
    }
    if (Object.keys(this.curdMethod).length) {
      this.setPrevQuery();
      this.initData();
      this.countMethod();
      this.initSearchInputEvent();
      this.curd.isSetHeight && this.setTableBodyHeight();
    }
    if (this.curd.isRowScroll) {
      this.$nextTick(() => {
        const table = this.$refs.table;
        if (table) {
          const container = document.querySelector(".lay-out-container");
          this.whellhandle = (e) => {
            e.preventDefault();
          }
          container.addEventListener('wheel', this.whellhandle);
          table.$el.onwheel = (e) => {
            table.$refs.mutipleTable.bodyWrapper.scrollLeft -= e.wheelDelta;
          };
        }
      })
    }
  },
  methods: {
    ...mapActions(["handleColumnsList"]),
    /**
     * 初始化普通参数
     */
    iniGeneralData() {
      if (this.columns) {
        this.default.columns = this.$deepClone(this.columns)
      }
      this.limit = this.pageSizes[0];
      if (this.curd && this.curd.isCache) {
        let columns =
          this.$store.state.table.columnsList[this.routeName] || this.columns;
        columns && this.handleColumns(columns);
      }
    },
    /**
     * 初始化默认参数
     */
    initDefaultValue() {
      this.default = {
        ...this.default,
        query: this.$deepClone(this.query),
        form: this.$deepClone(this.form),
        date: this.$deepClone(this.date),
        dateValue1: this.$deepClone(this.dateValue1),
        dateValue2: this.$deepClone(this.dateValue2),
      };
    },
    /**
     * 初始化查询日期（created中调用）
     */
    initDateValue(value, startTimeName, endTimeName, days = 30) {
      let startTime = moment().subtract(days, "days").format("YYYY-MM-DD");
      let endTime = moment().format("YYYY-MM-DD");
      this[value] = [startTime, endTime];
      if (startTimeName && endTimeName) {
        this.dateChangeValue(this[value], startTimeName, endTimeName, false);
      } else {
        this.dateChange(this[value], false);
      }
    },
    /**
     * 初始化查询时间（created中调用）
     */
    initDateTimeValue(value, startTimeName, endTimeName, days = 30, defaultTime = this.$config.defaultTime) {
      let startTime, endTime;
      if (days >= 0) {
        startTime = moment().subtract(days, "days").format("YYYY-MM-DD " + defaultTime[0]);
        endTime = moment().format("YYYY-MM-DD " + defaultTime[1]);
      } else {
        startTime = moment().format("YYYY-MM-DD " + defaultTime[0]);
        endTime = moment().subtract(days, "days").format("YYYY-MM-DD " + defaultTime[1]);
      }

      this[value] = [startTime, endTime];
      if (startTimeName && endTimeName) {
        this.dateChangeValue(this[value], startTimeName, endTimeName, false);
      } else {
        this.dateChange(this[value], false);
      }
    },
    /**
     * 初始化查询日期（年初到至今）（created中调用）
     */
    initYearDateValue(value, startTimeName, endTimeName) {
      let startTime = moment().startOf('year').format('YYYY-MM-DD');
      let endTime = moment().format("YYYY-MM-DD");
      this[value] = [startTime, endTime];
      if (startTimeName && endTimeName) {
        this.dateChangeValue(this[value], startTimeName, endTimeName, false);
      } else {
        this.dateChange(this[value], false);
      }
    },
    /**
     * 设置上一次查询query参数
     */
    setPrevQuery() {
      this.prevQuery = this.$deepClone(this.query);
    },
    /**
     * 初始化查询输入框事件
     */
    initSearchInputEvent() {
      let elementList = document.querySelectorAll(".form-search-input");
      [...elementList].map((element) => {
        element.addEventListener("keydown", (event) => {
          if (event.keyCode === 13) {
            this.searchData();
          }
        });
      });
    },
    /**
     * 移除查询输入框事件
     */
    removeSearchInputEvent() {
      let elementList = document.querySelectorAll(".form-search-input");
      [...elementList].map((element) => {
        element.addEventListener("keydown", null);
      });
    },
    /**
     * 初始化表格query查询参数
     */
    initQueryParams() {
      Object.assign(this.query, this.$route.params)
    },
    /**
     * 处理表格query查询参数
     */
    handleQueryParams() {
      let paramsKeyList = Object.keys(this.$route.params);
      let defaultQueryKeyList = Object.keys(this.default.query);
      let judge = paramsKeyList.some(item => {
        return defaultQueryKeyList.indexOf(item) !== -1;
      })
      judge && this.initData();
    },
    /**
     * 处理表格列
     */
    handleColumns(columns) {
      let originColumns = this.$deepClone(this.columns);
      let prevColumns = this.$deepClone(columns);
      // this.columns = this.$deepClone(
      //   columns.map((item, index) => {
      //     if (originColumns) {
      //       // 列配置需要
      //       item.display = !(item.display === false);

      //       let originItem = originColumns[index];
      //       // session中存储不了函数
      //       Object.keys(originItem).map(keyItem => {
      //         if (typeof originItem[keyItem] === "function") {
      //           item[keyItem] = originItem[keyItem];
      //         }
      //       })
      //       // if (originItem["formatter"]) {
      //       //   item["formatter"] = originItem["formatter"]
      //       // }
      //       // if (originItem["render-header"]) {
      //       //   item["render-header"] = originItem["render-header"]
      //       // }
      //       // if (originItem["render"]) {
      //       //   item["render"] = originItem["render"]
      //       // }
      //     }
      //     return item;
      //   })
      // );

      this.columns = originColumns.map((originItem) => {
        let prevItem = prevColumns.find(prevItem => {
          return originItem.prop === prevItem.prop;
        })
        let item = originItem;
        prevItem && Object.keys(originItem).map(keyItem => {
          // session中存储不了函数
          if (typeof originItem[keyItem] === "function") {
            item[keyItem] = originItem[keyItem];
          } else {
            if (prevItem[keyItem] !== undefined) {
              item[keyItem] = prevItem[keyItem];
            } else {
              item[keyItem] = originItem[keyItem];
            }
          }
          item.display = originItem.display || prevItem.display;
        })

        // 列配置需要
        item.display = !(item.display === false);
        return item;
      })
    },
    /**
     * 获取表格数据
     */
    initData(type = null, callback) {
      this.data = [];
      if (
        type != 2 &&
        (type === 1 ||
          JSON.stringify(this.prevQuery) !== JSON.stringify(this.query))
      ) {
        this.page = 1;
      }
      // 当上一次查询条件不等于当前查询条件时 查询数量接口
      if (JSON.stringify(this.prevQuery) !== JSON.stringify(this.query)) {
        this.countMethod();
      }
      this.setPrevQuery();
      if (this.isFunction(this.curdMethod.query)) {
        this.loading = true;
        this.searchLoading = true;
        this.curdMethod.query(this.getQueryParams()).then((res) => {
          this.loading = false;
          this.searchLoading = false;
          if (res.code === 1 && res.data) {
            if (this.curd.isPage) {
              this.data = res.data.records;
              this.total = res.data.totalCount;
            } else {
              this.data = res.data;
            }
            this.curdCallback.query && this.curdCallback.query(res.data);
            if (typeof callback == "function") {
              callback();
            }
          }
        });
      } else {
        // console.warn("this.curdMethod.query is not a function");
      }
    },
    /**
     * tab栏数量查询
     */
    countMethod() {
      if (this.isFunction(this.curdMethod.count)) {
        this.curdMethod.count(this.getQueryParams()).then((res) => {
          if (res.code === 1) {
            this.stateCount = res.data || {};
            this.curdCallback.count && this.curdCallback.count(res.data);
          }
        });
      } else {
        // console.warn("this.curdMethod.count is not a function");
      }
    },
    /**
     * 操作回调
     */
    operateCallback(data, message = "操作成功") {
      this.initData();
      this.countMethod();
      this.$message.success(message);
    },
    /**
     * 查询数据
     */
    searchData(callback) {
      debounceFn(() => this.initData(1, callback));
    },
    /**
     * 刷新数据
     */
    reloadData() {
      debounceFn(() => this.initData());
    },
    /**
     * tab切换查询数据
     */
    tabSearchData() {
      this.tabDebounce = true;
      setTimeout(() => {
        this.tabDebounce = false;
      }, 500);
      this.initData(1);
    },
    /**
     * tab 切换防抖
     */
    tabBeforeLeave() {
      return !this.tabDebounce;
    },
    /**
     * 改变页码
     */
    currentChange(page) {
      this.page = page;
      this.initData();
    },
    /**
     * 改变每页条数
     */
    sizeChange(limit) {
      this.limit = limit;
      this.initData(1);
    },
    /**
     * 获取查询参数
     */
    getQueryParams() {
      return {
        page: this.page,
        limit: this.limit,
        ...this.query,
        ...this.params,
      };
    },
    /**
     * 获取表单参数
     */
    getFormParams() {
      return {
        ...this.form,
      };
    },
    /**
     * 重置查询参数
     */
    resetQuery() {
      this.query = this.$deepClone(this.default.query);
      this.date = this.$deepClone(this.default.date);
      this.dateValue1 = this.$deepClone(this.default.dateValue1);
      this.dateValue2 = this.$deepClone(this.default.dateValue2);
    },
    /**
     * 重置表单参数
     */
    resetForm() {
      this.form = this.$deepClone(this.default.form);
    },
    /**
     * 打开弹窗或者页面
     */
    openDialog(title = "新增", operate = "add", row = {}, callback = null) {
      this.submitLoading = false;
      this.isAdd = operate === "add" ? true : false;
      if (this.isAdd) {
        this.currentRow = {};
        this.resetForm();
      } else {
        this.currentRow = this.$deepClone(row);
        this.form = this.$deepClone(this.currentRow);
      }
      this.operate = operate;
      this.dialog.title = title;
      this.dialog.visible = true;
      callback && callback();
    },
    /**
     * 关闭弹窗或者页面
     */
    closeDialog() {
      this.dialog.visible = false;
    },
    /**
     * 通用提交方法
     */
    submitMethod() {
      if (this.$refs["form"]) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            if (this.isAdd) {
              this.addMethod();
            } else {
              this.editMethod();
            }
          }
        });
      }
    },
    /**
     * 通用新增方法
     */
    addMethod() {
      if (this.isFunction(this.curdMethod.add)) {
        this.submitLoading = true;
        this.curdMethod.add(this.getFormParams()).then((res) => {
          if (res.code === 1) {
            this.closeDialog();
            this.initData();
            this.curdCallback.add && this.curdCallback.add(res.data);
          } else {
            this.submitLoading = false;
          }
        });
      } else {
        // console.warn("this.curdMethod.add is not a function");
      }
    },
    /**
     * 通用编辑方法
     */
    editMethod() {
      if (this.isFunction(this.curdMethod.edit)) {
        this.submitLoading = true;
        this.curdMethod.edit(this.getFormParams()).then((res) => {
          if (res.code === 1) {
            this.closeDialog();
            this.initData();
            this.curdCallback.edit && this.curdCallback.edit(res.data);
          } else {
            this.curdCallback.error && this.curdCallback.error(res);
            this.submitLoading = false;
          }
        });
      } else {
        // console.warn("this.curdMethod.edit is not a function");
      }
    },
    /**
     * 通用单个删除方法
     */
    deleteMethod(
      title = "删除操作",
      message = "确认删除这条数据吗？",
      row = {}
    ) {
      this.msgbox({
        title,
        message,
      })
        .then(() => {
          this.deleteLoading = true;
          const data = {};
          this.currentRow = this.$deepClone(row);
          data[this.curd.id] = this.currentRow[this.curd.id];
          if (this.isFunction(this.curdMethod.delete)) {
            this.curdMethod.delete(data).then((res) => {
              this.deleteLoading = false;
              if (res.code === 1) {
                this.initData(1);
                this.curdCallback.delete && this.curdCallback.delete(res.data);
              }
            });
          } else {
            // console.warn("this.curdMethod.delete is not a function");
          }
        })
        .catch(() => { });
    },
    /**
     * 通用多选处理方法
     */
    batchMethod(title = "批量删除", message = "确认删除选中数据吗？", options = null) {
      const idList = this.getSelectCheckboxIds();
      if (!idList.length) {
        this.$message.info("请选择数据");
        return;
      }
      message += `（共 ${idList.length} 项）`;
      this.msgbox({
        title,
        message,
      })
        .then(() => {
          const data = {};
          data[this.curd.idList] = idList;
          if (options !== null) {
            Object.assign(data, options)
          }
          this.batchLoading = true;
          if (this.isFunction(this.curdMethod.batchHandle)) {
            this.curdMethod.batchHandle(data).then((res) => {
              this.batchLoading = false;
              if (res.code === 1) {
                this.initData(1);
                this.curdCallback.batchHandle ?
                  this.curdCallback.batchHandle(res.data) :
                  this.operateCallback(res.data);
              }
            });
          } else {
            // console.warn("this.curdMethod.batchHandle is not a function");
          }
        })
        .catch(() => { });
    },
    /**
     * 通用多选修改状态方法
     */
    batchEditStatusMethod(
      title = "批量操作",
      message = "确认操作选中数据吗？",
      status = null,
      options = null
    ) {
      const idList = this.getSelectCheckboxIds();
      if (!idList.length) {
        this.$message.info("请选择数据");
        return;
      }
      message += `（共 ${idList.length} 项）`;
      this.msgbox({
        title,
        message,
      })
        .then(() => {
          const data = {};
          if (status !== null) {
            data[this.curd.status] = status;
          }
          if (options !== null) {
            Object.assign(data, options)
          }
          data[this.curd.idList] = idList;

          this.batchEditStatusLoading = true;
          if (this.isFunction(this.curdMethod.batchEditStatus)) {
            this.curdMethod.batchEditStatus(data).then((res) => {
              this.batchEditStatusLoading = false;
              if (res.code === 1) {
                this.initData(1);
                this.curdCallback.batchEditStatus ?
                  this.curdCallback.batchEditStatus(res.data) :
                  this.operateCallback(res.data);
              }
            });
          } else {
            // console.warn("this.curdMethod.batchEditStatus is not a function");
          }
        })
        .catch(() => { });
    },
    /**
     * 通用导出方法
     */
    exportMethod() {
      if (this.isFunction(this.curdMethod.export)) {
        this.exportLoading = true;
        this.curdMethod.export(this.getQueryParams()).then((res) => {
          this.exportLoading = false;
          if (res.code === 1) {
            // this.downloadMessage();
            this.curdCallback.export && this.curdCallback.export(res.data);
          }
        });
      } else {
        // console.warn("this.curdMethod.export is not a function");
      }
    },
    /**
     * 下载提示
     */
    downloadMessage() {
      return downloadMessage();
    },
    /**
     * 获取选中数据ID数组
     */
    getSelectCheckboxIds() {
      const selectionList = this.$refs.table.$refs.mutipleTable.selection;
      const idList = [];
      if (selectionList.length) {
        for (let i = 0; i < selectionList.length; i++) {
          idList.push(selectionList[i][this.curd.id]);
        }
      }
      return idList;
    },
    /**
     * 操作提示
     */
    msgbox({
      title,
      message
    }) {
      return new Promise((resolve, reject) => {
        this.$msgbox({
          title,
          message,
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    /**
     * 时间更改（单个）
     */
    dateChange(value, search = true) {
      if (value !== null && value.length) {
        this.query.startTime = value[0];
        this.query.endTime = value[1];
      } else {
        this.query.startTime = "";
        this.query.endTime = "";
      }
      search && this.searchData();
    },
    /**
     * 时间更改（多个）
     */
    dateChangeValue(value, startTimeName, endTimeName, search = true) {
      if (value !== null && value.length) {
        this.query[startTimeName] = value[0];
        this.query[endTimeName] = value[1];
      } else {
        this.query[startTimeName] = "";
        this.query[endTimeName] = "";
      }
      search && this.searchData();
    },
    clearDateValue(date, startTimeName, endTimeName, search = true) {
      this.query[startTimeName] = "";
      this.query[endTimeName] = "";
      this[date] = null;
      search && this.searchData();
    },
    /**
     * 处理表格多选数据
     */
    selectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 表头宽度拖拉回调
     */
    headerDragend(newWidth, oldWidth, column, event) {
      let index = this.columns.findIndex(item => {
        return item.prop === column.property;
      })
      let newItem = {
        ...this.columns[index],
        width: newWidth
      }
      this.columns[index] = newItem;
      this.columns = [...this.columns]
    },
    /**
     * 把columns存入session
     */
    saveColumnsToSession() {
      if (this.curd && this.curd.isCache) {
        this.handleColumnsList({
          key: this.routeName,
          value: this.columns,
        });
      }
    },
    /**
     * 设置表格内容高度/最大高度
     */
    async setTableBodyHeight() {
      await this.$nextTick();
      let viewHead = document.querySelector(".view-head");
      let viewContainerControl = document.querySelector(
        ".view-container-control"
      );
      const fontSize = document.documentElement.style.fontSize.replace("px", "");
      if (viewContainerControl) {
        let maxHeight = null,
          height = null,
          fixedViewContainerHeight = null;
        let layout = document.querySelector("#layout");
        let fixedHeight = 98 + 30 + (viewHead ? 16 : 0);
        maxHeight = layout.clientHeight - fixedHeight / 16 * fontSize - (viewHead ? viewHead.clientHeight : 0);
        let viewContainerTab = document.querySelector(".view-container-tab");
        if (viewContainerTab) {
          fixedViewContainerHeight = 20 + 72 + 14;
        } else {
          fixedViewContainerHeight = 20 + 24 + 14;
        }
        fixedViewContainerHeight += this.curd.isPage ? (20 + 32) : 0;
        maxHeight -= fixedViewContainerHeight / 16 * fontSize;
        height = maxHeight;
        this.options["height"] = height;
        // this.options["max-height"] = maxHeight;
        // this.$nextTick(() => {
        //   let tableBodyWrapper = document.querySelector(".el-table__body-wrapper");
        //   let maxHeight = window.getComputedStyle(tableBodyWrapper).maxHeight;
        //   if (maxHeight) {
        //     maxHeight = parseInt(maxHeight) - 1
        //     tableBodyWrapper.style.maxHeight = maxHeight + "px";
        //   }
        // });
      }
    },
    isFunction(value) {
      return Object.prototype.toString.call(value) ===
        "[object Function]";
    }
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    componentId() {
      return this.operate;
    },
  },
  watch: {
    "dialog.visible"(val) {
      if (!val) {
        if (this.$refs["form"]) {
          this.$refs["form"].clearValidate();
        }
      }
    },
    columns() {
      this.saveColumnsToSession();
    }
  },
  deactivated() {
    // this.removeSearchInputEvent();
    if (this.curd.isRowScroll) {
      const table = this.$refs.table;
      if (table) {
        const container = document.querySelector(".lay-out-container");
        container.removeEventListener('wheel', this.whellhandle);
      }
    }
  },
  beforeDestroy() {
    this.removeSearchInputEvent();
    if (this.curd.isRowScroll) {
      const table = this.$refs.table;
      if (table) {
        const container = document.querySelector(".lay-out-container");
        container.removeEventListener('wheel', this.whellhandle);
      }
    }
  },
};
<template>
  <div id="lay-out-head">
    <TagsViews class="head-left tagsviews" />
    <div class="head-right">
      <el-tooltip
        class="item primary-hover"
        effect="dark"
        content="全屏缩放"
        placement="bottom"
      >
        <screenfull />
      </el-tooltip>
      <el-dropdown
        trigger="click"
        :visible-arrow="false"
        @visible-change="visibleChange"
      >
        <div class="user-info">
          <img
            class="user-info-avatar"
            src="@/assets/img/logo-circle.png"
            alt=""
          />
          <span class="user-info-name">{{ userInfo.ssoOperatorNo }}</span>
          <i class="iconfont icon-up" :class="{ active: visible }"></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="user-info-dropdown">
          <!-- <el-dropdown-item
            @click="handleToUserInfo"
            icon="iconfont icon-a-zhanghao11"
            >个人中心</el-dropdown-item
          > -->
          <el-dropdown-item
            v-auth="['update_password']"
            icon="iconfont icon-bianji"
            @click.native="openDialog('修改密码', 'updatePassword')"
            >修改密码</el-dropdown-item
          >
          <el-dropdown-item
            icon="iconfont icon-kaiguan"
            @click.native="signOut.pop = true"
            >退出</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>

      <el-tooltip
        v-auth="['message']"
        class="item primary-hover"
        effect="dark"
        content="消息通知"
        placement="bottom"
      >
        <i
          class="iconfont icon-a-xiaoxi2"
          @click="handleToRoute('Message')"
        ></i>
      </el-tooltip>
      <el-tooltip
        v-auth="['download-center']"
        class="item primary-hover"
        effect="dark"
        content="下载中心"
        placement="bottom"
      >
        <i
          @click="handleToRoute('DownloadCenter')"
          class="iconfont icon-a-xiazai1"
        ></i>
      </el-tooltip>
      <el-tooltip
        v-auth="['polaris-data-market:query']"
        class="item primary-hover"
        effect="dark"
        content="北极星指标数据大盘"
        placement="bottom"
      >
        <img
          @click="handleToRoute('Quotas')"
          class="images-icon"
          src="https://dfs.uxuncloud.com/public/document/2023/08/07/Quotas_1688368458250719232.png"
        />
      </el-tooltip>
    </div>
    <el-dialog
      class="main-dialog"
      title="退出登录"
      :visible.sync="signOut.pop"
      :close-on-click-modal="false"
      append-to-body
    >
      <span>您确定退出系统吗?</span>
      <div slot="footer" class="dialog-footer">
        <el-button @click="signOut.pop = false">取消</el-button>
        <el-button type="primary" @click="signOutFn" :loading="signOut.loading"
          >确定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="main-dialog"
      :title="dialog.title"
      :visible.sync="dialog.visible"
      :close-on-click-modal="false"
      append-to-body
      @closed="operate = ''"
    >
      <component :is="componentId" ref="children"></component>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="submitMethod"
          >保存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TagsViews from "./tags-views/index";
import screenfull from "@/components/Screenfull/Index";
import updatePassword from "./update-password";

import { updateUserPassword } from "@/api/common";

import curd from "@/mixins/curd";
export default {
  name: "LayOutHead",
  mixins: [curd],
  components: { TagsViews, screenfull, updatePassword },
  computed: {
    ...mapGetters(["isCollapse"]),
  },
  data() {
    return {
      signOut: {
        btn: false,
        pop: false,
        loading: false,
      },
      visible: false,
    };
  },
  methods: {
    ...mapActions(["handleLogout"]),
    toggleSideBar() {
      this.$store.dispatch("settings/toggleSideBar");
    },
    signOutFn() {
      this.signOut.loading = true;
      this.handleLogout(true);
    },
    visibleChange(visible) {
      this.visible = visible;
    },
    handleToUserInfo() {},
    handleToRoute(name) {
      this.$router.push({ name });
    },
    submitMethod() {
      let children = this.$refs["children"];
      if (children.onSubmit()) {
        if (this.operate === "updatePassword") {
          this.curdMethod.edit = updateUserPassword;
          this.curdCallback.edit = () => {
            this.$message.success("修改密码成功，请重新登录");
            this.handleLogout(true);
          };
        }
        this.editMethod();
      }
    },
  },
  computed: {
    userInfo() {
      return this.$cookies.get(this.$config.userInfoKey) || {};
    },
    componentId() {
      return this.operate;
    },
  },
};
</script>

<style lang="scss" scoped>
#lay-out-head {
  display: flex;
  height: 36px;
  margin: 0 -30px;
  padding: 0 20px 0 30px;
  background: $--color-default;
  box-shadow: 0 1px 8px #d0d0d0;
  overflow-y: hidden;
}
.head-left {
  width: 1232px;
  margin-left: -12px;
}
.head-right {
  flex: 1;
  display: flex;
  align-content: flex-end;
  flex-direction: row-reverse;
  align-items: center;
  .user-info {
    display: flex;
    align-content: center;
    align-items: center;
    margin-left: 24px;
    cursor: pointer;
    &-avatar {
      margin-right: 8px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    &-name {
      font-size: 12px;
      margin-right: 8px;
    }
    .icon-up {
      transform: rotateZ(180deg);
      transition: all 0.3s;
      color: $--color-info;
      &.active {
        transform: rotateZ(0deg);
      }
    }
  }
  .item {
    margin-left: 24px;
  }
  .stretch-leave-active,
  .stretch-enter-active {
    transition: all 0.6s ease;
  }
  .stretch-leave-active,
  .stretch-enter {
    width: 0px !important;
  }
  .stretch-leave,
  .stretch-enter-active {
    width: 80px;
  }
  .sign-out {
    display: flex;
    align-items: center;
    margin-left: 12px;
    width: 80px;
    height: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .icon {
      margin-right: 5px;
      color: red;
      font-weight: 600;
    }
  }
  .iconfont {
    color: $--color-info;
    font-size: 22px;
  }
}
.user-info-dropdown {
  ::v-deep {
    .el-dropdown-menu__item {
      width: 100px;
      line-height: 32px;
      padding-left: 8px;
      padding-right: 0;
      i {
        margin-right: 8px;
      }
    }
  }
}
.images-icon{
  width: 22px;
  height: 22px;
}
</style>
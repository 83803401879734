import {
    axios
} from "@/utils/request"
import {
    getUserInfo
} from "@/utils/cookie"

/**
 * @param {Number} platform 平台标识【1-银销易、2-优品易、3-供应商】
 * @param {String} username 帐号
 * @param {String} password 密码
 * @param {String} graphicalCode 图形验证码
 * @param {Boolean} graphicalFlag 是否有图形验证
 * @param {String} graphicalKey 图形验证码key
 * @param {Boolean} sevenFreeLogin 7天免登录
 */

/**
 * 登录
 */
export const passwordLogin = ({
    username,
    password
}) => {
    const data = {
        username,
        password
    }
    return axios.request({
        data,
        method: "post",
        url: "/systemUser/login",
    })
}

/**
 * 查询当前账号权限
 */
export const queryOperatorAuth = () => {
    const data = {};
    return axios.request({
      data,
      method: "post",
      url: "/systemAuth/queryAuthByOperateId",
    });
  };

/**
 * 退出登录
 */
export const logout = () => {
    const data = getUserInfo()
    return axios.request({
        data,
        method: "post",
        url: "/systemUser/logout",
    })
}

/**
 * 上传文件
 */
export const uploadFile = ({
    file,
    type
}) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    return axios.request({
        data: formData,
        method: "post",
        url: "/common/upload",
        headers: {
            'content-type': 'multipart/form-data',
            "charset": "utf-8"
        },
    });
};
<template>
  <div id="expand">
    <el-button
      :loading="$parent.searchLoading"
      class="icon"
      type="info"
      v-if="reloadable"
      @click="onReload"
    >
      <i class="iconfont icon-gengxin" v-show="!$parent.searchLoading"></i>
    </el-button>
    <el-button
      :loading="$parent.exportLoading"
      :disabled="!$parent.data.length"
      class="icon"
      type="info"
      v-if="downloadable"
      @click="onDownload"
    >
      <i class="iconfont icon-daochu" v-show="!$parent.exportLoading"></i>
    </el-button>
    <el-button class="switch-content icon" type="info" v-if="switchable">
      <i
        class="iconfont icon-liebiao"
        @click="active = 1"
        :class="{ active: active === 1 }"
      ></i>
      <i
        class="iconfont icon-tubiao"
        @click="active = 2"
        :class="{ active: active === 2 }"
      ></i>
    </el-button>
    <el-popover
      v-if="columnable"
      v-model="model"
      placement="bottom-end"
      :visible-arrow="false"
      class="column-config"
      popper-class="uxun-popper"
      width="536"
    >
      <el-button
        class="config-button"
        slot="reference"
        type="info"
        @click="onColumnChange"
        >列配置
      </el-button>
      <div class="column-container">
        <div class="column-wrapper flex">
          <div class="column-item text-wrap">
            <el-checkbox
              v-model="selectAll"
              :indeterminate="indeterminate"
              @change="handleSelectAll"
              >全选</el-checkbox
            >
          </div>
          <div
            class="column-item text-wrap"
            v-for="(item, index) in columns"
            :key="index"
          >
            <el-checkbox
              :disabled="item.prop === 'operate'"
              v-model="item.display"
              >{{ item.label }}</el-checkbox
            >
          </div>
        </div>
        <div class="close">
          <el-button size="medium" @click="onClose">关闭</el-button>
          <el-button size="medium" type="primary" @click="onSubmit"
            >确认</el-button
          >
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { debounceFn } from "@/utils";
export default {
  name: "IExpand",
  props: {
    reloadable: {
      type: Boolean,
      default: true,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    switchable: {
      type: Boolean,
      default: false,
    },
    columnable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [],
      active: 1,
      model: false,
      selectAll: false,
      indeterminate: true,
    };
  },
  created() {
    this.initColumns();
  },
  methods: {
    initColumns() {
      this.columns = this.$deepClone(this.$parent.columns);
      this.initSelectAll();
    },
    initSelectAll() {
      let everyValue = this.columns.every((item) => {
        return item.display === true;
      });
      let someValue = this.columns.some((item) => {
        return item.display === true;
      });
      if (!someValue) {
        this.selectAll = false;
        this.indeterminate = false;
      } else {
        if (everyValue) {
          this.selectAll = true;
          this.indeterminate = false;
        } else {
          this.indeterminate = true;
        }
      }
    },
    handleSelectAll() {
      this.indeterminate = false;
      this.columns.forEach((item) => {
        if (item.prop !== "operate") {
          item.display = this.selectAll;
        }
      });
    },
    onReload() {
      debounceFn(() => this.$parent.reloadData());
    },
    onDownload() {
      if (!this.$parent.data.length) {
        return;
      }
      debounceFn(this.$parent.exportMethod);
    },
    onColumnChange() {},
    onClose() {
      this.model = false;
    },
    onSubmit() {
      this.onClose();
      this.$parent.columns = this.$deepClone(this.columns);
    },
  },
  watch: {
    model(val) {
      if (val) {
        this.initColumns();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#expand {
  float: right;
  display: flex;
  position: relative;
}
::v-deep {
  .el-button {
    &.icon {
      padding: 0;
      width: 32px;
      height: 32px;
    }
    &.icon {
      color: $--color-info;
      &:hover {
        color: $--color-primary;
      }
    }
    &.switch-content {
      width: 64px;
      span {
        display: flex;
        justify-content: space-around;
      }
      .iconfont {
        color: $--color-info;
        &.active,
        &:hover {
          color: $--color-primary;
        }
      }
    }
    .iconfont {
      font-size: 20px;
      &.icon-daochu {
        font-size: 24px;
      }
    }
  }
  .column-config {
    margin-left: 14px;
  }
}

.column-container {
  .column-wrapper {
    flex-wrap: wrap;
    margin: 8px 82px 28px 8px;
    .column-item {
      width: 33.333333%;
      line-height: 20px;
      margin-bottom: 20px;
      ::v-deep {
        .el-checkbox__label {
          color: $--color-font !important;
        }
      }
    }
  }
  .close {
    text-align: right;
    margin-bottom: 12px;
    margin-right: 8px;
  }
}
</style>
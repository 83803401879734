<template>
  <el-menu-item
    :index="MenuItem.path"
    :key="MenuItem.path"
  >
    <a :href="'#' + MenuItem.path">
      <div class="el-menu-item-wrapper">
        <i :class="['iconfont', MenuItem.meta.icon]"></i>
        <span
          slot="title"
          v-text="MenuItem.meta.title"
        ></span>
      </div>
    </a>
  </el-menu-item>
</template>

<script>
export default {
  name: "MenuItem",
  props: ["MenuItem"],
};
</script>
<style lang="scss" scoped>
::v-deep {
  a:hover {
    color: inherit;
  }
}
</style>

import LayOut from '@/layout/index'

export default [{
  path: "/order/",
  name: "Order",
  component: LayOut,
  meta: {
    icon: "icon-dingdanguanli",
    isMenu: true,
    title: '订单管理'
  },
  children: [
    {
      name: "OrderList",
      path: "list",
      component: () => import("@/views/order/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '订单列表'
      }
    }
  ]
}]
<template>
    <div id="hamburger" @click="toggleClick">
        <i class="el-icon-s-unfold" v-if="isCollapse"></i>
        <i class="el-icon-s-fold" v-else></i>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: 'Hamberger',
    methods: {
        toggleClick() {
            this.$emit('toggleClick');
        }
    },
    computed: {
      ...mapGetters(["isCollapse"])
    }
}
</script>

<style lang="scss" scoped>
#hamburger {
    display: inline-block;
    margin-right: 12px;
    font-size: 22px;
    cursor: pointer;
}
</style>
import router from "@/router";
import VueCookies from "vue-cookies";

// import {
//   passwordLogin,
//   logout,
//   queryGraphicVerificationCode,
//   checkToken,
// } from "@/api/common/sso";
import { passwordLogin, logout } from "@/api/common"
import { setToken, getToken, setCookie, removeCookie, setUserInfo } from "@/utils/cookie";

export default {
  state: {
    userInfo: {},
    token: getToken(),
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    // 登录
    handleLogin({ commit }, form) {
      form.username = form.username.trim();
      return new Promise((resolve, reject) => {
        passwordLogin(form)
          .then((res) => {
            if (res.code === 1) {
              let userInfo = JSON.stringify(res.data.user);
              setToken(res.data.token, "0");
              setUserInfo(userInfo, "0")
              commit("setToken", res.data.token);
              commit("setUserInfo", userInfo);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 退出登录
    handleLogout({ commit }, isApi = false) {
      return new Promise(async (resolve, reject) => {
        console.log(456)
        if (isApi) {
          try {
            // await logout();
          } catch (error) { }
        }
        VueCookies.keys().forEach((name) => {
          removeCookie(name);
        });
        router.push({
          name: "Login",
        });
        resolve();
      });
    },
    // 获取图形验证码
    getGraphicVerificationCode({ commit }) {
      return new Promise((resolve, reject) => {
        queryGraphicVerificationCode()
          .then((res) => {
            const data = res.data;
            data.ssographicalkey = res.headers.ssographicalkey;
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

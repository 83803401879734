<template>
  <div class="i-table">
    <el-table
      id="i-table"
      v-loading="loading"
      ref="mutipleTable"
      :data="data"
      :empty-text="emptyText"
      :height="options.height"
      :max-height="options['max-height']"
      :show-header="options['show-header']"
      :cell-style="options['cell-style']"
      :header-cell-style="options['header-cell-style']"
      :sort-orders="['descending']"
      :row-key="options['row-key']"
      :tree-props="options['tree-props']"
      :default-expand-all="options['default-expand-all']"
      :stripe="options.stripe"
      :border="options.border"
      :span-method="options['span-method']"
      :header-cell-class-name="options['header-cell-class-name']"
      :cell-class-name="options['cell-class-name']"
      :row-class-name="options['row-class-name']"
      @select="select"
      @select-all="selectAll"
      @selection-change="selectionChange"
      @sort-change="sortChange"
      @row-contextmenu="rowContextMenu"
      @row-click="rowClick"
      @header-dragend="headerDragend"
    >
      <el-table-column
        v-if="options.selection"
        label-class-name="table-selection"
        :selectable="options['selectable']"
        :reserve-selection="options['reserveSelection']"
        type="selection"
        width="40"
      >
      </el-table-column>
      <template v-for="(column, index) in insideColumns">
        <template v-if="column.display">
          <el-table-column
            :key="index + column.label"
            :type="column.type"
            :label="column.label"
            :prop="column.prop"
            :width="column.width"
            :min-width="column['min-width'] || 120"
            :fixed="column.fixed"
            :resizable="column.resizable"
            :sortable="column.sortable"
            :show-overflow-tooltip="
              column['show-overflow-tooltip'] === undefined
                ? false
                : column['show-overflow-tooltip']
            "
            :align="column.align"
            :header-align="column['header-align']"
            :class-name="column['class-name']"
            :label-class-name="column['label-class-name']"
            :reserve-selection="options['reserveSelection']"
          >
            <template slot="header" slot-scope="scope">
              <expand-dom
                v-if="column['render-header']"
                :column="column"
                :row="scope.row"
                :render="column['render-header']"
                :index="scope.$index"
              ></expand-dom>
              <span v-else>{{ column.label }}</span>
            </template>
            <template slot-scope="scope">
              <template v-if="!column.render">
                <template v-if="column.formatter">
                  <span
                    v-html="column.formatter(scope.row, column, scope.$index)"
                  ></span>
                </template>
                <template v-else>
                  <span>{{ scope.row[column.prop] ? scope.row[column.prop] : "-" }}</span>
                </template>
              </template>
              <template v-else>
                <expand-dom
                  :column="column"
                  :row="scope.row"
                  :render="column.render"
                  :index="scope.$index"
                ></expand-dom>
              </template>
            </template>
          </el-table-column>
        </template>
      </template>
      <template slot="empty">
        <slot name="empty"></slot>
      </template>
    </el-table>
  </div>
</template>
<script>
import TablesEdit from "./Edit";
export default {
  name: "tables",
  props: {
    /**
     * @description 表格数据
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * @description 表格字段
     * @field display 控制单个字段显示隐藏
     */
    columns: {
      type: Array,
      default: () => [],
    },
    /**
     * @description 数据加载中
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * @description 表格组件其他属性或者自定义属性（基于element table、有就用、无则加）
     */
    options: {
      type: Object,
      default: () => {
        return {
          stripe: false,
          selection: false,
          border: false,
          resizable: false,
          "default-expand-all": false,
          "row-key": "id",
          "header-cell-style": {},
          "cell-style": {},
          "tree-props": {},
          "header-cell-class-name": () => {
            return "";
          },
          selectable: () => {
            return true;
          },
          reserveSelection: false,
        };
      },
    },
    /**
     * @description 数据为空的文案
     */
    emptyText: {
      type: String,
      default: "暂无数据",
    },
    /**
     * @description 全局设置是否可编辑
     */
    editable: {
      type: Boolean,
      default: false,
    },
  },
  //组件
  components: {
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: (h, ctx) => {
        const params = {
          row: ctx.props.row,
          index: ctx.props.index,
        };
        if (ctx.props.column) params.column = ctx.props.column;
        return ctx.props.render(h, params);
      },
    },
  },
  data() {
    return {
      insideColumns: [], // 重新处理的columns
      insideTableData: [], // 重新处理的data
      edittingCellId: "", // 正在编辑行的column
      edittingText: null, // 正在编辑的文案
    };
  },
  mounted() {
    this.handleColumns(this.columns);
    this.handleTableData(this.data);
  },
  computed: {},
  methods: {
    // handleSelectable(row, index) {
    //   // return row.disabled === false ? false : true;
    //   return !row.disabled;
    // },
    suportEdit(item, index) {
      item.render = (h, params) => {
        return h(TablesEdit, {
          props: {
            params: params,
            value: params.row[params.column.prop],
            edittingCellId: this.edittingCellId,
            editable: this.editable,
          },
          on: {
            input: (val) => {
              this.edittingText = val;
            },
            "on-start-edit": (params) => {
              this.edittingCellId = `editting-${params.index}-${params.column.prop}`;
              this.$emit("on-start-edit", params);
            },
            "on-save-edit": (params) => {
              if (this.edittingText === "" || this.edittingText === null)
                return;
              this.$emit(
                "on-save-edit",
                Object.assign(params, { value: this.edittingText }),
                this.onSaveEditCallBack
              );
            },
            "on-cancel-edit": (params) => {
              this.edittingCellId = "";
              this.$emit("on-cancel-edit", params);
            },
          },
        });
      };
      return item;
    },
    handleColumns(columns) {
      this.insideColumns = this.$deepClone(columns).map((item, index) => {
        item.resizable = !!(
          this.options &&
          this.options.resizable &&
          !(item.resizable === false)
        );
        item.display = !(item.display === false);
        let res = item;
        if (res.editable) res = this.suportEdit(res, index);
        return res;
      });
    },
    handleTableData(data) {
      this.insideTableData = data.map((item, index) => {
        let res = item;
        res.initRowIndex = index;
        return res;
      });
    },
    // 编辑字段保存回调
    onSaveEditCallBack(errorMsg) {
      if (errorMsg) {
        this.$message.info(errorMsg);
      } else {
        this.edittingCellId = "";
      }
    },
    // emit 方法
    selectionChange(selection) {
      this.$emit("selection-change", selection);
    },
    select(selection, row) {
      this.$emit("select", selection, row);
    },
    selectAll(selection) {
      this.$emit("select-all", selection);
    },
    sortChange({ column, prop, order }) {
      this.$emit("sort-change", column, prop, order);
    },
    rowContextMenu(row, column, event) {
      this.$emit("row-contextmenu", row, column, event);
    },
    rowClick(row, column, event) {
      this.$emit("row-click", row, column, event);
    },
    headerDragend(newWidth, oldWidth, column, event) {
      this.$emit("header-dragend", newWidth, oldWidth, column, event);
    },
  },
  watch: {
    columns(columns) {
      this.handleColumns(columns);
    },
    // columns: {
    //   handler(columns) {
    //     this.handleColumns(columns);
    //   },
    //   deep: true,
    // },
    data(data) {
      this.handleTableData(data);
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>

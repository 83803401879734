import axios from "axios"
// import store from "@/store"
import {
    Message
} from "element-ui";
// import {
//     throttle
// } from "@/utils"
// import {
//     dataEncrypt
// } from "./des"
import {
    getUserInfo,
    getToken
} from "@/utils/cookie"

// const handleThrottle = throttle(() => {}, 2000)

class Request {
    constructor(baseURL, timeout) {
        this.baseURL = baseURL;
        this.timeout = timeout;
        this.noCheckTokenApi = [];
    }
    getInsideConfig() {
        const config = {
            timeout: this.timeout,
            baseURL: this.baseURL,
            headers: {},
        }
        return config
    }
    interceptors(instance, options) {
        // 请求拦截
        instance.interceptors.request.use(async config => {
            // if (!getUserInfo() && !getToken()) {
            //     window.location.reload();
            //     return;
            // }
            if (options.method === "post") {

                Object.assign(config.headers, {
                    "Authorization": getToken()
                })
                // 过滤没有token的接口
                if (this.noCheckTokenApi.indexOf(config.url) === -1) {
                    // token校验（不包含本地环境）
                    // if (process.env.NODE_ENV !== "development") {
                    //     await store.dispatch("handleCheckToken");
                    // }
                }
            }
            return config
        }, error => {
            return Promise.reject(error)
        })
        // 响应拦截
        instance.interceptors.response.use(response => {
            if (!response.data) {
                Message.error("请求无响应")
                return response;
            }
            if (response.data.code !== 1 && response.data.msg) {
                // // 个别场景错误提示用dialog替换message
                // if (response.data.code !== 4059) {
                //     if (handleThrottle() !== false) {
                //         return Message.error(response.data.msg)
                //     }
                // }
                return Message.error(response.data.msg)
            } else {
                // to do
            }
            // sso图形验证码返回完整请求信息
            // if (response.headers.ssographicalkey) {
            //     return response;
            // } else {
            return response.data;
            // }
        }, error => {
            return Promise.reject(error)
        })
    }
    /**
     * 普通api请求不需要加密
     */
    request(options) {
        const instance = axios.create()
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options)
        return instance(options)
    }
}

export default Request;
import LayOut from '@/layout/index'

export default [{
  path: "/system/",
  name: "System",
  component: LayOut,
  meta: {
    icon: "icon-shezhi",
    isMenu: true,
    title: '系统管理'
  },
  children: [
    {
      name: 'User',
      path: 'user',
      component: () => import('@/views/system/user/index'),
      meta: {
        cache: true,
        isMenu: true,
        title: '用户管理'
      }
    },
    {
      name: 'Role',
      path: 'role',
      component: () => import('@/views/system/role/index'),
      meta: {
        cache: false,
        isMenu: true,
        title: '角色管理'
      }
    },
    {
      name: 'Auth',
      path: 'auth',
      component: () => import('@/views/system/auth/index'),
      meta: {
        cache: true,
        isMenu: true,
        title: '权限管理'
      }
    }
  ]
}]
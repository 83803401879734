<template>
  <div class="sunmenu-item">
    <el-submenu :index="subMenu.path">
      <template slot="title">
        <i :class="['iconfont', subMenu.meta.icon]"></i>
        <span slot="title" v-text="subMenu.meta.title"></span>
      </template>
      <template v-for="menu_2 in subMenu.children">
        <!-- 三级菜单
        <template v-if="menu_2.children.length > 0">
          <el-submenu
            v-for="menu_3 in menu_2.children"
            :index="menu_2.path"
            :key="menu_3.path"
          >
            <template slot="title">
              <i :class="['iconfont', menu_2.meta.icon]"></i>
              <span slot="title" v-text="menu_2.meta.title"></span>
            </template>
            <menuItem :key="menu_3.path" :MenuItem="menu_3" />
          </el-submenu>
        </template> -->
        <!-- 二级菜单 -->
        <menuItem :key="menu_2.path" :MenuItem="menu_2" />
      </template>
    </el-submenu>
  </div>
</template>

<script>
import menuItem from "./item.vue";
export default {
  name: "SubMenu",
  props: ["subMenu"],
  components: { menuItem },
};
</script>

<style lang="scss" scoped>
.el-menu--collapse {
  > div {
    > .el-submenu {
      .el-submenu__title {
        span {
          height: 0;
          width: 0;
          overflow: hidden;
          visibility: hidden;
          display: inline-block;
        }
      }
    }
  }
}
</style>
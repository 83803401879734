import VueCookies from "vue-cookies"
import config from "@/config"
const {
  tokenKey,
  userInfoKey,
  cookieExpires,
  domain,
  cookieMountTopLevelDominNameKeys
} = config
export const TOKEN_KEY = tokenKey;
export const USERINFO_KEY = userInfoKey;

// 只有 TOKEN_KEY、USERINFO_KEY、sevenFreeLogin 字段设置的域是一级域名 其他都是当前域名

/**
 * 设置token
 * @param {String} token token key
 * @param {String}} expires 有效期【'0'-关闭浏览器的时候销毁】
 * 
 */
export const setToken = (token, expires = cookieExpires) => {
  if (process.env.NODE_ENV === "development" || cookieMountTopLevelDominNameKeys.indexOf(TOKEN_KEY) === -1) {
    VueCookies.set(TOKEN_KEY, token, expires)
  } else {
    VueCookies.set(TOKEN_KEY, token, expires, null, domain)
  }
}

/**
 * 获取token
 */
export const getToken = () => {
  const token = VueCookies.get(TOKEN_KEY)
  return token ? token : false;
}

/**
 * 设置用户信息
 * @param {String} value value
 * @param {String}} expires 有效期【'0'-关闭浏览器的时候销毁】
 */
export const setUserInfo = (value, expires = cookieExpires) => {
  if (process.env.NODE_ENV === "development" || cookieMountTopLevelDominNameKeys.indexOf(USERINFO_KEY) === -1) {
    VueCookies.set(USERINFO_KEY, value, expires)
  } else {
    VueCookies.set(USERINFO_KEY, value, expires, null, domain)
  }
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
  return VueCookies.get(USERINFO_KEY)
}

/**
 * 设置cookie信息
 * @param {String} name cookie Key
 * @param {String} value cookie value
 * @param {String}} expires 有效期【'0'-关闭浏览器的时候销毁】
 */
export const setCookie = (name, value, expires = cookieExpires) => {
  if (process.env.NODE_ENV === "development" || cookieMountTopLevelDominNameKeys.indexOf(name) === -1) {
    VueCookies.set(name, value, expires)
  } else {
    VueCookies.set(name, value, expires, null, domain)
  }
}

/**
 * 获取cookie信息
 * @param {String} name cookie Key
 */
export const getCookie = (name) => {
  return VueCookies.get(name)
}

/**
 * 移除cookie信息
 * @param {String} name cookie Key
 */
export const removeCookie = (name) => {
  if (process.env.NODE_ENV === "development" || cookieMountTopLevelDominNameKeys.indexOf(name) === -1) {
    VueCookies.remove(name)
  } else {
    VueCookies.remove(name, null, domain)
  }
}
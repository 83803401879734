import {
  queryOperatorAuth
} from '@/api/common'

export default {
  state: {
      userAuthModules: [],
  },
  mutations: {
      setUserAuthModules(state, data) {
          state.userAuthModules = data
      },
  },
  actions: {
      // 获取用户菜单权限
      async getUserAuth({
          commit,
      }) {
          return new Promise((resolve, reject) => {
              queryOperatorAuth().then((res) => {
                  if (res.code === 1) {
                      resolve(res)
                      commit('setUserAuthModules', res.data)
                  }
              }).catch(err => {
                  reject(err)
              })
          })
      },
  }
}
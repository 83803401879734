import LayOut from '@/layout/index'

export default [{
  path: "/channel/",
  name: "Channel",
  component: LayOut,
  meta: {
    icon: "icon-gongyingshangguanli",
    isMenu: true,
    title: '门店管理'
  },
  children: [
    {
      name: "AgentList",
      path: "agent-list",
      component: () => import("@/views/channel/agent"),
      meta: {
        cache: true,
        isMenu: true,
        title: '渠道列表'
      }
    },
    {
      name: "ChannelList",
      path: "list",
      component: () => import("@/views/channel/list"),
      meta: {
        cache: true,
        isMenu: true,
        title: '门店列表'
      }
    },
  ]
}]
<template>
  <div id="app-lay-out">
    <keep-alive>
      <router-view v-if="$route.meta.cache" class="lay-out-window" />
    </keep-alive>
    <router-view v-if="!$route.meta.cache" class="lay-out-window" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // exclude: ["FictitiousStore", "CommodityDetails"],
    };
  },
};
</script>

<style lang="scss" scoped>
#app-lay-out {
  flex: 1;
  padding: 0 30px 30px;
  padding-top: 54px;
  position: relative;
}
</style>
<style lang="scss">
#app-lay-out {
  &.active {
    padding-bottom: 0 !important;
  }
}
</style>
<template>
  <div id="sidebar" :class="{ active: isCollapse }">
    <div class="logo-container flex" @click="toHome">
      <img class="logo" v-if="!isCollapse" src="@/assets/img/logo.png" alt="" />
      <img class="small-logo" v-else src="@/assets/img/small-logo.png" alt="" />
    </div>
    <el-scrollbar>
      <el-menu
        @select="selectMenuFn"
        :collapse="isCollapse"
        :unique-opened="uniqueOpen"
        :default-active="activeMenu"
        :collapse-transition="false"
        class="el-menu-vertical-demo"
        active-text-color="#fff"
      >
        <template v-for="item in menuList">
          <!-- 二三级菜单 -->
          <subMenu
            v-if="item.children && item.children.length"
            :key="item.path"
            :subMenu="item"
          />
          <!-- 一级菜单 -->
          <menuItem v-else :key="item.path" :MenuItem="item" />
        </template>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import subMenu from "./submenu.vue";
import menuItem from "./item.vue";

import config from "@/config";

import routes from "@/router/routes";
export default {
  name: "Sidebar",
  components: { subMenu, menuItem },
  data() {
    return {
      menuIndex: "/home",
      menuList: [],
      authRouterList: [],
    };
  },
  created() {
    this.initRouterList();
    this.initMenuList();
  },
  methods: {
    initRouterList() {
      this.$store.state.auth.userAuthModules.map((item) => {
        this.authRouterList.push(item.authRoute);
      });
    },
    initMenuList() {
      const data = this.$deepClone(routes).filter((item) => {
        if (item.children) {
          item.children = item.children.filter((childrenItem) => {
            childrenItem.path = item.path + childrenItem.path;
            return (
              (this.authRouterList.indexOf(childrenItem.name) !== -1 ||
                childrenItem.name === config.homeName) &&
              childrenItem.meta &&
              childrenItem.meta.isMenu
            );
            // return childrenItem.meta && childrenItem.meta.isMenu;
          });
        }

        return (
          (this.authRouterList.indexOf(item.name) !== -1 ||
            item.name === config.homeName) &&
          item.meta &&
          item.meta.isMenu
        );
        // return item.meta && item.meta.isMenu;
      });
      this.menuList = data;
      // 工作台属于第一个菜单 单独处理
      this.menuList[0] = this.menuList[0].children[0];
    },
    selectMenuFn(path) {
      this.menuIndex = path;
      if (path !== this.$route.path) this.$router.push({ path });
    },
    toHome() {
      this.$router.push({ name: this.$config.homeName });
    },
  },
  computed: {
    ...mapGetters(["isCollapse", "uniqueOpen"]),
    activeMenu() {
      const { path } = this.$route;
      if (path) return path;
    },
  },
};
</script>
<style lang="scss" scoped>
#sidebar {
  z-index: 10;
}
.logo-container {
  height: 64px;
  align-items: center;
  justify-content: center;
  background: rgba(44, 44, 44, 1);
  border-bottom: 1px solid rgba(75, 75, 75, 1);
  cursor: pointer;
  .logo {
    height: 36px;
  }
}
</style>
<style lang="scss">
#sidebar {
  &.active {
    .el-submenu__icon-arrow {
      display: none;
    }
  }
}
</style>
<template>
  <el-popover
    v-if="src"
    placement="right"
    trigger="hover"
    popper-class="uxun-poppper"
  >
    <img :src="src" class="small-img" alt="" slot="reference" />
    <img :src="src" class="medium-img" alt="" />
  </el-popover>
  <img v-else :src="empty" class="empty" alt="" />
</template>

<script>
import empty from "@/assets/img/null.png";
export default {
  props: ["src"],
  data() {
    return {
      empty,
    };
  },
};
</script>

<style lang="scss">
.small-img {
  cursor: pointer;
  object-fit: contain;
}
.medium-img {
  width: 300px;
  height: 300px;
  object-fit: contain;
}
</style>
<template>
  <div class="i-footer" :style="style">
    <div class="i-footer-content flex center">
      <div class="btns">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    el: {
      type: String,
      default: ".lay-out-container",
    },
    position: {
      type: String,
      default: "sticky",
    },
    width: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleAddElement() {
      const node = this.$mount().$el;
      if (this.el === "") {
        document.body.appendChild(node);
      } else {
        document.querySelector(this.el).appendChild(node);
      }
      if (this.el === ".lay-out-container") {
        let element = document.querySelector("#app-lay-out");
        element.classList.add("active");
      }
    },
    handleRemoveElement() {
      const node = this.$mount().$el;
      node.remove();
      if (this.el === ".lay-out-container") {
        let element = document.querySelector("#app-lay-out");
        element.classList.remove("active");
      }
    },
  },
  mounted() {
    this.handleAddElement();
  },
  activated() {
    this.handleAddElement();
  },
  deactivated() {
    this.handleRemoveElement();
  },
  destroyed() {
    this.handleRemoveElement();
  },
  computed: {
    style() {
      const style = {
        position: this.position,
      };
      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.i-footer {
  box-shadow: 0px -2px 4px rgba(60, 64, 77, 0.1);
  width: 100%;
  background-color: #fff;
  bottom: 0;
  left: 0;
  z-index: 10;
  .i-footer-content {
    height: 80px;
  }
}
</style>
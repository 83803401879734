<template>
  <div id="tags-view">
    <scroll-pane>
      <draggable animation="300">
        <transition-group>
          <router-link
            ref="tag"
            tag="span"
            :key="tag.path"
            v-for="tag in tagsView"
            @contextmenu.prevent.native="openMenu(tag, $event)"
            :class="['tags-view-pane', isActive(tag) ? 'tag-active' : '']"
            :to="{ path: tag.path, fullPath: tag.fullPath, query: tag.query }"
          >
            <span class="tag-title text-wrap">{{ tag.title }}</span>
            <span
              v-if="!isOneTag"
              class="el-icon-close"
              @click.prevent.stop="colseTag(tag)"
            />
          </router-link>
        </transition-group>
      </draggable>
    </scroll-pane>

    <!-- 省略开关 -->
    <span class="ellipsis" v-show="isEllipsis" @click.stop="EllipsiFn">...</span>

    <!-- 单标签操作 -->
    <ul
      v-show="visible"
      class="contextmenu"
      :style="{ left: left + 'px', top: top + 'px' }"
    >
      <!-- <li @click="refreshSelectedTag(selectedTag)">刷新</li> -->
      <li @click="colseTag(selectedTag)">关闭</li>
      <li @click="closeOthersTags">关闭其他</li>
      <li @click="closeAllTags()">关闭全部</li>
    </ul>

    <!-- 展开所有 标签 -->
    <div class="dropdown" v-show="isDropdown">
      <div class="dropdown-links scroll-bar">
        <router-link
          ref="tag"
          tag="span"
          :key="tag.path"
          class="dropdown-item"
          v-for="(tag, index) in tagsView"
          :to="{ path: tag.path, fullPath: tag.fullPath, query: tag.query }"
        >
          <div
            :class="[
              'dropdown-name',
              isActive(tag) || dragIndex === index ? 'active' : '',
            ]"
            @mouseover="dragIndex = index"
            @mouseleave="dragIndex = ''"
          >
            <span class="tag-title text-wrap">{{ tag.title }}</span>
            <span
              class="el-icon-close"
              v-show="dragIndex === index"
              @click.prevent.stop="colseTag(tag)"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import ScrollPane from "./scroll-pane.vue";
export default {
  name: "TagsView",
  components: { ScrollPane, draggable },
  computed: {
    ...mapGetters(["tagsView"]),
  },
  data() {
    return {
      top: 0,
      left: 0,
      tag: null,
      dragIndex: "",
      visible: false,
      isOneTag: false,
      selectedTag: {},
      isEllipsis: false,
      isDropdown: false,
    };
  },
  watch: {
    $route() {
      this.addPane();
    },
    tagsView(v) {
      if (v.length >= 9) {
        this.isEllipsis = true;
      } else {
        this.isEllipsis = false;
        this.isDropdown = false;
      }
      if (v.length === 0) this.$router.push("/home");
      // 当标签只有一个 不显示关闭标签按钮或菜单
      v.length === 1 ? this.isOneTag = true : this.isOneTag = false;
    },
    visible(value) {
      if (value) {
        document.body.addEventListener("click", this.closeMenu);
      } else {
        document.body.removeEventListener("click", this.closeMenu);
      }
    },
    isDropdown(value) {
      if (value) {
        document.body.addEventListener("click", this.closeDropdown);
      } else {
        document.body.removeEventListener("click", this.closeDropdown);
      }
    },
  },
  mounted() {
    this.addPane();
  },
  updated() {
    this.tagsScrollFn();
  },
  methods: {
    isActive(tag) {
      if(tag.path === this.$route.path) this.tag = tag;
      return tag.path === this.$route.path;
    },
    tagsScrollFn() {
      if(this.tagsView.length > 8 && this.tag.path === this.$route.path) {
        let tagItem = document.getElementsByClassName('tag-active')[0];
        let tagsScroll = document.getElementById('tags-scroll').children[0];

        // 滚动条滚动值 = 当前标签左偏移 - 滚动盒子宽度 + 当前标签宽度
        tagsScroll.scrollLeft = tagItem.offsetLeft - tagsScroll.clientWidth + tagItem.clientWidth || 0;
      }
    },
    addPane() {
      const { name } = this.$route;
      if (name) this.$store.dispatch("tagsView/addTagsView", this.$route);
    },
    colseTag(tag) {
      this.$store.dispatch("tagsView/deleteTags", tag).then((visitedViews) => {
        this.toLastView(visitedViews);
      });
    },
    closeOthersTags() {
      if (this.$route.path !== this.selectedTag.path) {
        this.$router.push(this.selectedTag);
      }
      this.$store.dispatch("tagsView/delOthersViews", this.selectedTag);
    },
    closeAllTags() {
      this.$store.dispatch("tagsView/closeAllTags").then(() => {
        if (this.$route.path !== "/home") {
          this.$router.push("/home");
        }
      });
    },
    toLastView(visitedViews) {
      let latestView = visitedViews.slice(-1)[0];

      if (this.$route.path !== latestView.path) {
        latestView ? this.$router.push(latestView) : this.$router.push("/");
      }
    },
    openMenu(tag, e) {
      if(this.isOneTag) return;
      const menuMinWidth = 105;
      const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
      const offsetWidth = this.$el.offsetWidth; // container width
      const maxLeft = offsetWidth - menuMinWidth; // left boundary
      let left = e.clientX - offsetLeft + 30; // 15: margin right

      this.left = left;

      this.top = e.clientY;
      this.selectedTag = tag;
      this.visible = true;
    },
    closeMenu() {
      this.visible = false;
    },
    EllipsiFn() {
      this.visible = false;
      this.isDropdown = !this.isDropdown;
    },
    closeDropdown() {
      this.isDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins.scss";
#tags-view {
  position: absolute;
  .ellipsis {
    display: inline-block;
    position: absolute;
    right: -30px;
    top: 18%;
    width: 24px;
    height: 24px;
    line-height: 14px;
    font-size: 18px;
    color: $--color-font;
    text-align: center;
    background: #dcdde0;
    border-radius: 4px;
    cursor: pointer;
  }
}

.tags-view-pane {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 0 16px;
  width: 135px;
  height: 36px;
  font-size: 12px;
  line-height: 36px;
  color: $--color-font;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  .tag-title {
    display: inline-block;
    width: 80px;
    height: 36px;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
  }
  &:hover {
    background: rgba($color: #fff, $alpha: 0.7);
  }
  &::after {
    content: "";
    width: 1px;
    height: 14px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: rgba($--color-info, 0.5);
  }
  &.tag-active {
    background-color: #fff;
    &::after {
      display: none !important;
    }
  }
  .el-icon-close {
    // margin-left: 38px;
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 600;
    color: $--color-info;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:before {
      display: inline-block;
    }
    &:hover {
      background-color: #dcdde0;
    }
  }
}
.tags-view-pane:nth-of-type(1) {
  margin-left: 16px;
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  li {
    margin: 0;
    padding: 7px 16px;
    width: 80px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
.dropdown {
  position: absolute;
  top: 35px;
  right: -145px;
  padding: 8px 4px 8px 0;
  background: #fff;
  border-radius: 4px;
  z-index: 10;
  @include Boxshadow;

  .dropdown-links {
    width: 140px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .dropdown-item {
    cursor: pointer;
    height: 32px;
    line-height: 32px;

    .dropdown-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      &.active {
        color: $--color-primary;
        background: $--color-primary-is_light !important;
      }
      .tag-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .el-icon-close {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
</style>


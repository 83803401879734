import LayOut from '@/layout/index'

export default [{
  path: "/finance/",
  name: "Finance",
  component: LayOut,
  meta: {
    icon: "icon-caiwuduizhang",
    isMenu: true,
    title: '财务对账'
  },
  children: [
    {
      name: "ChannelBill",
      path: "channel-bill",
      component: () => import("@/views/finance/bill/channel/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '门店账单'
      }
    },
    {
      name: "MerchantBill",
      path: "merchant-bill",
      component: () => import("@/views/finance/bill/merchant/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '商户日账单'
      }
    },
    {
      name: "MerchantBillDetails",
      path: "merchant-bill/details",
      component: () => import("@/views/finance/bill/merchant/details/index"),
      meta: {
        title: '详情',
        parent: [{
          title: "商户日账单",
          path: "/finance/merchant-bill",
        }],
      }
    },
    {
      name: "ChannelSettlement",
      path: "channel-settlement",
      component: () => import("@/views/finance/settlement/channel/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '门店结算'
      }
    },
    {
      name: "ChannelSettlementDetails",
      path: "channel-settlement/details",
      component: () => import("@/views/finance/settlement/common/details/index"),
      meta: {
        title: '详情',
        parent: [{
          title: "门店结算",
          path: "/finance/channel-settlement",
        }],
      }
    },
    {
      name: "RebateSettlement",
      path: "rebate-settlement",
      component: () => import("@/views/finance/settlement/rebate/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '返点结算'
      }
    },
    {
      name: "MerchantSettlement",
      path: "merchant-settlement",
      component: () => import("@/views/finance/settlement/merchant/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '商户结算'
      }
    },
    {
      name: "SettlementUpdateBill",
      path: "settlement/update-bill",
      component: () => import("@/views/finance/settlement/common/update"),
      meta: {
        title: "调整账单",
        parent: [{
          title: "门店账单",
          path: "/finance/channel-bill",
        }],
      },
    },
    {
      name: "ChannelBillDetails",
      path: "channel-bill/details",
      component: () => import("@/views/finance/bill/channel/details/index"),
      meta: {
        title: '详情',
        parent: [{
          title: "门店账单",
          path: "/finance/channel-bill",
        }],
      }
    },
    {
      name: "FinancePayment",
      path: "payment",
      component: () => import("@/views/finance/payment/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '财务付款'
      }
    }
  ]
}]
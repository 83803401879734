<template>
  <div id="screenfull">
    <span
      @click="toggle"
      :class="[
        'iconfont',
        isScreenfull ? 'icon-tuichuquanping' : 'icon-quanping',
      ]"
    ></span>
  </div>
</template>

<script>
import screenfull from "screenfull";
export default {
  name: "Screenfull",
  data() {
    return {
      isScreenfull: false,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    toggle() {
      screenfull.toggle();
    },
    change() {
      this.isScreenfull = screenfull.isFullscreen;
    },
    init() {
      if (screenfull.enabled) {
        screenfull.on("change", this.change);
      }
    },
    destroy() {
      if (screenfull.enabled) {
        screenfull.off("change", this.change);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-tuichuquanping,
.icon-quanping {
  font-size: 18px;
  color: $--color-info;
}
</style>
import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import Auth from '@/utils/auth';
import ElementUI from 'element-ui';
import VueCookie from 'vue-cookies';
import Global from '@/utils/global';
import 'element-ui/lib/theme-chalk/index.css';

import '@/utils/rem';
import '@/utils/directives'; // 自定义指令

import '@/assets/styles/index.scss';
import '@/assets/iconfont/iconfont.css';
import '@/assets/iconfont/icomoon.css';
import '@/assets/styles/element-ui.scss';


Vue.use(Auth);
Vue.use(Global);
Vue.use(ElementUI);
Vue.use(VueCookie);

/**
 * @description 生产环境关掉提示
 */
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


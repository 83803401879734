import LayOut from '@/layout/index'

export default [{
  path: "/merchant/",
  name: "Merchant",
  component: LayOut,
  meta: {
    icon: "icon-gongyingshangguanli",
    isMenu: true,
    title: '商户管理'
  },
  children: [
    {
      name: "MerchantList",
      path: "list",
      component: () => import("@/views/merchant/index"),
      meta: {
        cache: true,
        isMenu: true,
        title: '商户列表'
      }
    },
  ]
}]
<template>
  <div class="tables-edit-outer">
    <div class="tables-edit-con">
      <span
        v-html="
          params.column.formatter
            ? params.column.formatter(params.row, params.column, params.index)
            : value
        "
      ></span>
      <div
        v-if="editable"
        @click="(event) => startEdit(event)"
        class="tables-edit-btn primary"
      >
        <i class="iconfont icon-bianji"></i>
      </div>
    </div>
    <div
      v-if="isEditting"
      class="tables-editting-con"
      ref="edit"
      :style="style"
      @click="(event) => stopPropagation(event)"
    >
      <el-card class="box-card">
        <el-input
          size="small"
          v-model="edittingText"
          @input="handleInput"
          clearable
          @keyup.enter.native="(event) => saveEdit(event)"
          class="tables-edit-input"
        >
        </el-input>
        <div class="tables-edit-save" @click="(event) => saveEdit(event)">
          <el-button type="primary">保存</el-button>
        </div>
        <div class="tables-edit-cancel" @click="(event) => canceltEdit(event)">
          <el-button type="info">取消</el-button>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    params: Object,
    editable: Boolean,
    edittingCellId: String,
  },
  data() {
    return {
      edittingText: "",
      top: "",
      left: "",
      style: {},
    };
  },
  mounted() {},
  computed: {
    isEditting() {
      return (
        this.edittingCellId ===
        `editting-${this.params.index}-${this.params.column.prop}`
      );
    },
  },
  methods: {
    stopPropagation(event) {
      event.stopPropagation();
    },
    handleInput(value) {
      this.$emit("input", value);
    },
    startEdit(event) {
      this.stopPropagation(event);
      this.$emit("on-start-edit", this.params);
      this.top = event.y;
      this.left = event.x;
      this.getStyle();
    },
    saveEdit(event) {
      this.stopPropagation(event);
      this.$emit("on-save-edit", this.params);
    },
    canceltEdit(event) {
      this.stopPropagation(event);
      this.$emit("on-cancel-edit", this.params);
    },
    getStyle() {
      this.$nextTick(() => {
        let allWidth = window.screen.width;
        let ele = this.$refs["edit"];
        let width = parseInt(window.getComputedStyle(ele).width);

        let top = this.top - 28 + "px";
        let left =
          (this.left + 10 + width > allWidth
            ? this.left - width - 10
            : this.left + 10) + "px";
        this.style = { top, left };
      });
    },
  },
  watch: {
    isEditting() {
      if (this.isEditting) {
        this.edittingText = this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tables-edit-outer {
  .tables-edit-con {
    display: flex;
    justify-content: space-between;
    min-height: 23px;
    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .tables-edit-btn {
      cursor: pointer;
      display: none;
      height: 23px;
      i {
        font-size: 16px;
      }
    }
    &:hover {
      .tables-edit-btn {
        display: inline-block;
      }
    }
  }
  .tables-editting-con {
    position: fixed;
    z-index: 1000;
    ::v-deep {
      .el-card__body {
        padding: 12px;
        display: flex;
        align-items: center;
        .el-input__inner {
          max-width: 500px;
          width: auto;
        }
      }
    }

    .tables-edit-save,
    .tables-edit-cancel {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-left: 12px;
    }
  }
}
</style>
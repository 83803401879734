import Vue from 'vue';
import {
    is_number,
    is_float
} from './reg';
import {
    Message
} from "element-ui";

const IS_FLOAT = /^(\d+)(\.\d+)?$/;
// 全局指令
const directives = {
    numcheck: { // 数字校验
        inserted(el, binding, vnode) {
            let iscancel = false;
            let v = '',
                value = '',
                maxLength = '',
                arg = binding.arg || ['int']; // 数字类型
            if (!Array.isArray(arg) && typeof arg === 'string') arg = arg.split(':');

            const inputEvent = e => {
                if (iscancel) return false;

                v = e.target.value;
                value = e.target.value; // 为绑定的值
                maxLength = binding.value || 11;

                if (arg.some(i => i === 'int')) { // 正整数
                    if (value.length && !is_number(value)) {
                        v = value.slice(0, value.length - 1);
                    }
                } else if (arg.some(i => i === 'float')) { // 浮点数
                    if (value.length && !IS_FLOAT.test(Number(value))) {
                        v = value.slice(0, value.length - 1);
                    }
                    if (value.indexOf('.') !== -1) { // 保留两位小数
                        if (value.split('.')[1].length > 2) {
                            v = value.slice(0, value.length - 1);
                        }
                    }
                }

                if (value.length > maxLength) { // 长度限制
                    v = value.slice(0, maxLength);
                }

                vnode.data.model.callback(v.trim()); // 改变虚拟节点上v-model的值
            }
            el.oninput = inputEvent;

            // 解决输入中文的问题
            el.addEventListener('compositionstart', e => iscancel = true);
            el.addEventListener('compositionend', e => {
                iscancel = false;
                e.target.value = e.target.value.split(e.data)[0];
                inputEvent(e)
            });

            // 失焦
            el.addEventListener('blur', e => {
                if (e.target.value === '') return;
                if (arg.some(i => i === 'float' || i === 'toFixed')) {
                    e.target.value = Number(e.target.value).toFixed(2);
                    inputEvent(e);
                }

                if (!arg.some(i => i === 'nomin') && e.target.value <= 0) {
                    Message.error('数值不能小于或等于零');
                    e.target.value = '';
                    inputEvent(e);
                }
            }, true)
        }
    },
    number: {
        inserted(el, binding, vnode) {
            let arg = binding.arg;

            const inputEvent = e => {
                let value = e.target.value.trim();
                if (value === "") {
                    return;
                }

                if (!is_number(value)) {
                    console.log(value, arg)
                    if (arg === undefined) { // 验证是否是数字
                        // if (Number(value) !== 0) {
                        //     value = "";
                        // }
                    } else if (arg === "int") { // 验证是否是正整数
                        value = "";
                    } else if (arg === "float") { // 验证是否是浮点数
                        let maxLength = binding.value || 2;
                        // if (!is_float(value) && Number(value) !== 0) {
                        if (!is_float(value)) {
                            value = "";
                        } else {
                            if (is_float(value) && !is_float(value, 1, maxLength)) {
                                value = Number(value).toFixed(maxLength)
                            }
                        }
                    }
                }

                e.target.value = value;
                vnode.data.model.callback(value); // 改变虚拟节点上v-model的值
            }

            el.addEventListener('blur', e => {
                inputEvent(e)
            }, true)
        },
        unbind(el, binding, vnode) {
            el.removeEventListener('blur', null)
        }
    },
}

Vue.directive('numcheck', directives.numcheck);
Vue.directive('number', directives.number);
import axios from "axios";
import { Message } from "element-ui";
import secretKey from "@/utils/axios/secret.key";
import CryptoJS from "crypto-js";

/**
 * 深拷贝
 * @param {Number|String|Object|...} target 需克隆的对象
 * @returns {Number|String|Object|...} 返回克隆后的对象
 */
export function deepClone(target) {
  let result;
  if (typeof target === "object") {
    if (Array.isArray(target)) {
      result = [];
      for (let i in target) {
        result.push(deepClone(target[i]));
      }
    } else if (target === null) {
      result = null;
    } else if (target.constructor === RegExp) {
      result = target;
    } else {
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
  } else {
    result = target;
  }
  return result;
}

/**
 * 处理树表数据
 * @param {Array} originData 平铺式源数据
 * @param {Number} id ID字段
 * @param {Number} parentId 父ID字段
 * @returns {Array} 返回处理过的树表数据
 */
export const handleTreeTableData = (originData, id, parentId) => {
  let data = deepClone(originData);
  let result = [],
    ids = [];
  data.map((item) => {
    ids.push(item[id]);
  });
  data.forEach((item1) => {
    if (ids.indexOf(item1[parentId]) !== -1) {
      data.forEach((item2, index2) => {
        if (item1[parentId] === item2[id]) {
          if (data[index2].children) {
            data[index2].children.push(item1);
          } else {
            data[index2].children = [item1];
          }
        }
      });
    } else {
      result.push(item1);
    }
    // if (item1[parentId] === 0) {
    //   result.push(item1);
    // }
  });
  return result;
};
// 下载文件
export const downloadFile = (obj, name = "", suffix = "") => {
  let url = obj;
  if (Object.prototype.toString.call(obj) !== "[object String]") {
    url = window.URL.createObjectURL(new Blob([obj]));
  }
  if (name === "") {
    name = url.split("/")[url.split("/").length - 1].split(".")[0];
  }
  if (suffix === "") {
    suffix = url.split(".")[url.split(".").length - 1];
  }
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  const fileName = name + "." + suffix;
  a.setAttribute("download", fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

// 下载链接文件（转成blob格式）
export const downloadLinkFile = (url, name = "", suffix = "") => {
  if (url) {
    if (name === "") {
      name = url.split("/")[url.split("/").length - 1].split(".")[0];
    }
    if (suffix === "") {
      suffix = url.split(".")[url.split(".").length - 1];
    }
  }
  axios
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      if (response.status === 200) {
        const blob = response.data;
        downloadFile(blob, name, suffix);
      }
    });
};

// 防抖
let debounceTimer = null;
export const debounceFn = (fn, wait = 800) => {
  if (debounceTimer === null) {
    fn();
    debounceTimer = setTimeout(() => {
      debounceTimer = null;
      clearTimeout(debounceTimer);
    }, wait);
  }
};

/**
 * 节流
 * @param {function} callback 回调函数
 * @param {number} time 定时参数
 * @returns function
 */
export const throttle = (callback, time) => {
  let timer = null;
  return (...args) => {
    if (timer) return false;
    callback(args);
    timer = setTimeout(() => {
      timer = null;
      clearTimeout(timer);
    }, time);
  };
};

/**
 * 防抖
 * @param {function} callback 回调函数
 * @param {number} time 定时参数
 * @returns function
 */
export const debounce = (callback, time) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      callback(args);
      timer = null;
    }, time);
  };
};

/**
 * 下载提示方法
 */
export const downloadMessage = (duration = 3000) => {
  let pathname = window.location.pathname;
  Message({
    duration,
    type: "success",
    showClose: true,
    dangerouslyUseHTMLString: true,
    message: `已执行下载任务,请前往<a href="${pathname}#/download/download-center" class="primary download-a">下载中心</a>下载任务`,
  });
};

/**
 * 数据加密
 * @param {String|Object} data 明文
 * @returns {String} 返回密文
 */
export const encrypt = (data) => {
  if (typeof data === "object") {
    try {
      data = JSON.stringify(data);
    } catch (error) {
      console.log("encrypt error:", error);
    }
  }
  data = data.toString();
  const dataHex = CryptoJS.enc.Utf8.parse(secretKey);
  const encrypted = CryptoJS.DES.encrypt(data, dataHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/**
 * 数据解密
 * @param {String} data 密文
 * @returns {String} 返回明文
 */
export const decrypt = (data) => {
  const dataHex = CryptoJS.enc.Utf8.parse(secretKey);
  const decrypted = CryptoJS.DES.decrypt(
    {
      ciphertext: CryptoJS.enc.Base64.parse(data),
    },
    dataHex,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
};

/**
 * px转rem
 * @param {Number} px
 * @return {String} 返回rem的数值
 */
export const pxTorem = (px) => {
  const baseSize = 16;
  const scale = document.documentElement.clientWidth / 1920;
  const fontSize = baseSize * Math.min(scale, 2);
  return Number(px) / Number(fontSize);
};

/**
 * 数组去重
 * @param {Array} data
 * @param {String} key
 * @return {Array} result
 */

export function duplicateRemoval(data, key = null) {
  return data.filter((item, i, arr) => {
    let temp = [];
    if (key) {
      arr.forEach((item1) => temp.push(item1[key]));
      return temp.indexOf(item[key]) === i;
    } else {
      arr.forEach((item1) => temp.push(item1));
      return temp.indexOf(item) === i;
    }
  });
}

/**
 * 数字按千位逗号分割
 * @param {Number} s，需要格式化的数值
 * @param {Number} type, 判断格式化后的数值是否需要小数位
 * @return {String} 返回格式化后的数值字符串
 */
export const parseFormatNum = (s, type = false) => {
  let sub = false;
  if (String(s).includes("-")) {
    s = Math.abs(s);
    sub = true;
  }
  String(s).indexOf(".") !== -1 && (type = true);
  if (/[^0-9\.]/.test(s)) return "0";
  if (s === null || s === "") return "0";
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while (re.test(s)) s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if (!type) {
    // 不带小数位(默认是有小数位)
    var a = s.split(".");
    if (a[1] === "00") {
      s = a[0];
    }
  }
  return sub ? "-" + s : s;
};

/**
 * 将科学计数法的数字转为字符串
 * 说明：运算精度丢失方法中处理数字的时候，如果出现科学计数法，就会导致结果出错
 * 4.496794759834739e-9  ==> 0.000000004496794759834739
 * 4.496794759834739e+9  ==> 4496794759.834739
 * @param  num
 */
export const toNonExponential = (num) => {
  if (num == null) {
    return num;
  }
  if (typeof num == "number") {
    let m = num.toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/);
    return num.toFixed(Math.max(0, (m[1] || "").length - m[2]));
  } else {
    return num;
  }
};

/**
 * 乘法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 * 100 ==> 0.22999999999999998
 * {{ 0.0023 | multiply(100) }} ==> 0.23
 */
export const floatMultiply = (arg1, arg2) => {
  arg1 = Number(arg1);
  arg2 = Number(arg2);
  if ((!arg1 && arg1 !== 0) || (!arg2 && arg2 !== 0)) {
    return null;
  }
  arg1 = toNonExponential(arg1);
  arg2 = toNonExponential(arg2);
  let n1, n2;
  let r1, r2; // 小数位数
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  n1 = Number(arg1.toString().replace(".", ""));
  n2 = Number(arg2.toString().replace(".", ""));
  return (n1 * n2) / Math.pow(10, r1 + r2);
};

/**
 * 除法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 / 0.00001 ==> 229.99999999999997
 * {{ 0.0023 | divide(0.00001) }} ==> 230
 */
export const floatDivide = (arg1, arg2) => {
  arg1 = Number(arg1);
  arg2 = Number(arg2);
  if (!arg2) {
    return null;
  }
  if (!arg1 && arg1 !== 0) {
    return null;
  } else if (arg1 === 0) {
    return 0;
  }
  arg1 = toNonExponential(arg1);
  arg2 = toNonExponential(arg2);
  let n1, n2;
  let r1, r2; // 小数位数
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  n1 = Number(arg1.toString().replace(".", ""));
  n2 = Number(arg2.toString().replace(".", ""));
  return floatMultiply(n1 / n2, Math.pow(10, r2 - r1));
  // return (n1 / n2) * Math.pow(10, r2 - r1);   // 直接乘法还是会出现精度问题
};

// toFixed重置
export function toFixedFun(num, decimal = 2) {
  num = num.toString();
  let index = num.indexOf(".");
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1);
  } else {
    num = num.substring(0);
  }
  return parseFloat(num).toFixed(decimal);
}

/**
 * 换算百分比
 * @param arg1  数1
 * @param arg2  数2
 */
export const floatPercentage = (arg1, arg2) =>{
  let num = floatDivide(arg1, arg2);
  num = Number(toFixedFun(num, 4));
  const str = floatMultiply(num, 100) ? floatMultiply(num, 100) : 0;
  return str;
}

/**
 * 加法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 + 0.00000000000001 ==> 0.0023000000000099998
 * {{ 0.0023 | plus(0.00000000000001) }} ==> 0.00230000000001
 */
export const floatAdd = (arg1, arg2) => {
  arg1 = Number(arg1) || 0;
  arg2 = Number(arg2) || 0;
  arg1 = toNonExponential(arg1);
  arg2 = toNonExponential(arg2);
  let r1, r2, m;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  return (floatMultiply(arg1, m) + floatMultiply(arg2, m)) / m;
};

/**
 * 减法 - js运算精度丢失问题
 * @param arg1  数1
 * @param arg2  数2
 * 0.0023 - 0.00000011  ==>  0.0022998899999999997
 * {{ 0.0023 | minus( 0.00000011 ) }}  ==>  0.00229989
 */
export const floatSub = (arg1, arg2) => {
  arg1 = Number(arg1) || 0;
  arg2 = Number(arg2) || 0;
  arg1 = toNonExponential(arg1);
  arg2 = toNonExponential(arg2);
  let r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  // 动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((floatMultiply(arg1, m) - floatMultiply(arg2, m)) / m).toFixed(n);
};
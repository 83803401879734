const clientWidth = document.documentElement.clientWidth;
const ratio1 = 1920, ratio2 = 1600, ratio3 = 1366;

const data = {
    80: {
        valueOf() {
            let width = 80;
            if(clientWidth <= ratio1) {
                width = 80;
            }
            if(clientWidth <= ratio2) {
                width = 80;
            }
            if(clientWidth <= ratio3) {
                width = 70;
            }
            return width;
        }
    },
    90: {
        valueOf() {
            let width = 90;
            if(clientWidth <= ratio1) {
                width = 90;
            }
            if(clientWidth <= ratio2) {
                width = 90;
            }
            if(clientWidth <= ratio3) {
                width = 80;
            }
            return width;
        }
    },
    100: {
        valueOf() {
            let width = 100;
            if(clientWidth <= ratio1) {
                width = 100;
            }
            if(clientWidth <= ratio2) {
                width = 100;
            }
            if(clientWidth <= ratio3) {
                width = 90;
            }
            return width;
        }
    },
    110: {
        valueOf() {
            let width = 110;
            if(clientWidth <= ratio1) {
                width = 110;
            }
            if(clientWidth <= ratio2) {
                width = 100;
            }
            if(clientWidth <= ratio3) {
                width = 90;
            }
            return width;
        }
    },
    120: {
        valueOf() {
            let width = 120;
            if(clientWidth <= ratio1) {
                width = 120;
            }
            if(clientWidth <= ratio2) {
                width = 120;
            }
            if(clientWidth <= ratio3) {
                width = 100;
            }
            return width;
        }
    },
    130: {
        valueOf() {
            let width = 130;
            if(clientWidth <= ratio1) {
                width = 130;
            }
            if(clientWidth <= ratio2) {
                width = 130;
            }
            if(clientWidth <= ratio3) {
                width = 110;
            }
            return width;
        }
    },
    150: {
        valueOf() {
            let width = 150;
            if(clientWidth <= ratio1) {
                width = 150;
            }
            if(clientWidth <= ratio2) {
                width = 140;
            }
            if(clientWidth <= ratio3) {
                width = 130;
            }
            return width;
        }
    },
    160: {
        valueOf() {
            let width = 160;
            if(clientWidth <= ratio1) {
                width = 160;
            }
            if(clientWidth <= ratio2) {
                width = 140;
            }
            if(clientWidth <= ratio3) {
                width = 130;
            }
            return width;
        }
    },
    170: {
        valueOf() {
            let width = 170;
            if(clientWidth <= ratio1) {
                width = 170;
            }
            if(clientWidth <= ratio2) {
                width = 150;
            }
            if(clientWidth <= ratio3) {
                width = 140;
            }
            return width;
        }
    },
    180: {
        valueOf() {
            let width = 180;
            if(clientWidth <= ratio1) {
                width = 180;
            }
            if(clientWidth <= ratio2) {
                width = 170;
            }
            if(clientWidth <= ratio3) {
                width = 160;
            }
            return width;
        }
    },
    200: {
        valueOf() {
            let width = 200;
            if(clientWidth <= ratio1) {
                width = 200;
            }
            if(clientWidth <= ratio2) {
                width = 180;
            }
            if(clientWidth <= ratio3) {
                width = 160;
            }
            return width;
        }
    },
    210: {
        valueOf() {
            let width = 210;
            if(clientWidth <= ratio1) {
                width = 210;
            }
            if(clientWidth <= ratio2) {
                width = 180;
            }
            if(clientWidth <= ratio3) {
                width = 170;
            }
            return width;
        }
    },
    270: {
        valueOf() {
            let width = 270;
            if(clientWidth <= ratio1) {
                width = 270;
            }
            if(clientWidth <= ratio2) {
                width = 230;
            }
            if(clientWidth <= ratio3) {
                width = 220;
            }
            return width;
        }
    },
    300: {
        valueOf() {
            let width = 300;
            if(clientWidth <= ratio1) {
                width = 300;
            }
            if(clientWidth <= ratio2) {
                width = 260;
            }
            if(clientWidth <= ratio3) {
                width = 240;
            }
            return width;
        }
    }
}

/**
 * 1920px转不同分辨率px
 * @param {Number} px
 * @return {String} px
 */
export const ratioPixel = (px) => {
    return data[px] ? data[px].valueOf() : 70;
}
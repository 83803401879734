<template>
  <div id="breadcrumb">
    <hamburger @toggleClick="toggleClick" />
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in levelList" :key="item.path">
        <a v-if="item.path === '/'" @click="$router.push('/')">{{
          item.title
        }}</a>
        <span
          v-else
          :class="index !== 1 && index !== levelList.length - 1 ? 'primary-hover' : ''"
          @click="index !== 1 && backFn(item, index)"
          >{{ item.title }}</span
        >
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import Hamburger from "@/components/Hamburger/Hamburger";
export default {
  name: "Breadcrumb",
  components: { Hamburger },
  data() {
    return {
      levelList: [],
      hasParamsRoute: {},
    };
  },
  watch: {
    $route(route) {
      this.getBreadcrumb();
    },
  },
  mounted() {
    this.getBreadcrumb();
  },
  methods: {
    backFn(item, index) {
      if (
        item.path &&
        item.path !== this.$route.path &&
        index !== this.levelList.length - 1
      ) {
        this.$router.replace(item.path);
        // this.$router.go(-1);
      }
    },
    getBreadcrumb() {
      let matched = [];

      // 过滤带有参数 路由
      if (Object.keys(this.$route.params).length)
        this.hasParamsRoute[this.$route.name] = this.$route.path;

      this.$route.matched.filter((item) => {
        if (item.meta.parent && item.meta.parent.length) {
          item.meta.parent.map((par) => {
            // path替换 通过meta.parent.name 识别是否同一个路由
            if (par.name in this.hasParamsRoute)
              par.path = this.hasParamsRoute[par.name];
          });
          matched.push(...item.meta.parent);
        }
        if (item.meta && item.meta.title) {
          matched.push({ path: item.path, title: item.meta.title });
        }
      });
      const FIRST = matched[0];

      if (!this.isDashboard(FIRST)) {
        this.levelList = [{ path: "/", title: "工作台" }].concat(matched);
      }
    },
    isDashboard(route) {
      const NAME = route && route.name;
      if (!NAME) return false;
      return NAME.trim().toLocaleLowerCase() === this.$config.homeName;
    },
    toggleClick() {
      this.$store.dispatch("settings/toggleSideBar");
    },
  },
};
</script>

<style lang="scss" scoped>
.el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: $--color-primary;
}
#breadcrumb {
  display: flex;
  align-items: center;
  margin-top: 14px;
}
</style>
import config from "@/config"
import {
    deepClone,
    downloadLinkFile,
    parseFormatNum,
    encrypt,
    decrypt
} from "@/utils";
import {
    ratioPixel
} from "@/utils/ratio"

import ITable from "@/components/Common/Table/Index";
import IPagination from "@/components/Common/Pagination/Index";
import IExpand from "@/components/Common/IExpand/Index";
import IFooter from "@/components/Common/Footer/Index";
import IImage from "@/components/Common/IImage/Index";
import ElInput1 from "@/components/Common/ElInput1/Index";
import ElSelect1 from "@/components/Common/ElSelect1/Index";
import ElDatePicker1 from "@/components/Common/ElDatePicker1/Index";

const global = {
    install(Vue) {
        this.init(Vue);
    },
    init(Vue) {
        this.initComponent(Vue);
        this.initConfig(Vue);
        this.initFunction(Vue)
    },
    // 全局注册组件
    initComponent(Vue) {
        Vue.component("ITable", ITable);
        Vue.component("IPagination", IPagination);
        Vue.component("IExpand", IExpand);
        Vue.component("IFooter", IFooter);
        Vue.component("IImage", IImage);
        Vue.component("ElInput1", ElInput1);
        Vue.component("ElSelect1", ElSelect1);
        Vue.component("ElDatePicker1", ElDatePicker1);
    },
    // 全局注册配置
    initConfig(Vue) {
        Vue.prototype.$config = config;
        Vue.prototype.$EventBus = new Vue();
    },
    // 全局注册函数
    initFunction(Vue) {
        Vue.prototype.$deepClone = deepClone;
        Vue.prototype.$encrypt = encrypt;
        Vue.prototype.$decrypt = decrypt;
        Vue.prototype.$downloadLinkFile = downloadLinkFile;
        Vue.prototype.$parseFormatNum = parseFormatNum;
        Vue.prototype.$ratioPixel = ratioPixel;
    },
}
export default global;
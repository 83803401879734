<template>
  <el-scrollbar
    id="tags-scroll"
    class="scroll-container"
    ref="scrollContainer"
    :vertical="false"
    @wheel.native.prevent="handleScroll"
  >
    <slot />
  </el-scrollbar>
</template>

<script>
export default {
  name: "ScrollPane",
  computed: {
    scrollWrapper() {
      return this.$refs.scrollContainer.$refs.wrap;
    },
  },
  methods: {
    handleScroll(e) {
      let $scrollWrapper = this.scrollWrapper;
      let eventDelta = e.wheelDelta || -e.deltaY * 40;
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4;

      // let tagsProp = {};
      // let dragProp = {};
      // let tags = document.getElementById('tags-view');
      // let drag = document.getElementById('drag-group');

      // for(let prop in tags) tagsProp[prop] = tags[prop];
      // for(let prop in drag) dragProp[prop] = drag[prop];

      // // 显示省略号
      // if(dragProp.offsetWidth > $scrollWrapper.clientWidth) {
      //   tags.classList.remove('active');
      //   tags.classList.add('active');
      // }

      // // 滚动长度 与 父级宽度 之和 大于或等于 内容长度 隐藏 省略号
      // if($scrollWrapper.scrollLeft + $scrollWrapper.clientWidth >= dragProp.offsetWidth) {
      //   tags.classList.remove('active');
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-container {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  width: 100%;
  ::v-deep {
    .el-scrollbar__bar {
      bottom: 0px;
    }
    .el-scrollbar__wrap {
      margin: 0 !important;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .ellipsis {
    position: absolute;
    right: 0;
  }
}
</style>
